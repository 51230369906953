@import 'c/main/src/constants';
@import 'c/main/src/type';

.family-page.initialised {
  opacity: 1;
  @include short-desktop() {
    grid-template-rows: min-content $h1-container-height 0;
  }

  .winelogo__container-link,
  .logo-container,
  .content  {
    @include phone {
      margin: 0 $horizontal-margin-phone / 2;
    }
  }

  .family {
    &__title-section {
      margin-right: $horizontal-margin-phone / 2;
      margin-left: $horizontal-margin-phone / 2;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;

      @include desktop-and-tablet {
        @include top-left-container;
        margin-right: $horizontal-margin;
        margin-left: $horizontal-margin;
        display: flex;
        position: relative;
      }

      @include desktop {
        @include top-left-container;
        display: flex;
      }

      h1 {
        margin-top: 4rem;
        flex-shrink: 1;
        text-align: center;
        order: 2;

        @include desktop-and-tablet {
          position: absolute;
          bottom: -3rem;
          left: 0;
          margin-bottom: 0;
          text-align: left;
          order: unset;
        }

        @include desktop {
          position: initial;
          margin: 4rem 0 1em;
        }
      }
    }
    &__title-image {
      display: flex;
      order: 1;

      @include desktop-and-tablet {
        display: none;
        order: unset;
      }

      @include desktop {
        display: flex;
      }

      .image {
        flex-basis: 50%;
        width: 100%;
        height: auto;
        margin-right: auto;

        img {
          width: 100%;
          height: auto;
        }
      }
    }
    &__content {
      display: flex;
      flex-wrap: wrap;
      margin-right: $horizontal-margin-phone / 2;
      margin-left: 25%;

      @include desktop-and-tablet {
        @include top-right-container;
        grid-column: 1 / 4 !important;
        display: flex;
        margin-right: $horizontal-margin;
        margin-left: $horizontal-margin;
        max-width: 1200px;
        margin-right: auto;
      }

      @include desktop {
        @include top-right-container;
        grid-column: 2 / 3 !important;
        display: flex;
        margin-left: 0;
      }
    }

    &__detail {
      &--main {
        flex-basis: 80%;
        min-height: 10rem;
        p {
          @include desktop-and-tablet {
            margin-right: 20%;
          }
        }
        &.mobile-only {
          order: 3;
          @include desktop-and-tablet {
            display: none;
          }
        }

        &.desktop-only {
          display: none;
          @include desktop-and-tablet {
            display: block;
            margin-top: $vert-margin;

            p:first-child {
              margin-top: 0;
            }
          }
        }

        blockquote {
          margin-left: 0;
          margin-right: 0;
          margin-bottom: 0;
          @include phone {
            @include domaine(32px);
            text-transform: uppercase;
            text-align: center;
            width: 100%;
          }
          p {
            margin-right: 0;
            margin-bottom: 0.5em;
          }
        }

        blockquote + p {
          text-align: center;
          margin-right: 0;
          margin-top: 0;
        }
      }

      &--column {
        flex-basis: 100%;
        padding-right: $horizontal-margin-phone / 2;
        @include tiempos(12px);

        @include desktop-and-tablet {
          padding-right: $horizontal-margin * 2;
          flex-basis: 33%;
          @include tiempos(
            $body-font-min * $min-font-ratio,
            $body-font-min * $min-font-ratio,
            $body-font-max * $min-font-ratio
          );
        }

        @include desktop {
          flex-basis: 33%;
        }

        &:last-child {
          padding-right: 0;
        }
      }
    }

    &__images {
      margin: 0 0 $nextPageMobileHeight 0;
      // margin-right: $horizontal-margin / 2;
      // padding-bottom: $vert-margin;
      width: 100%;

      @include desktop-and-tablet {
        @include bottom-full-container;
        margin-right: 0;
        margin-bottom: $vert-margin;
        padding-bottom: 0;
      }

      @include desktop {
        @include bottom-full-container;
        margin-right: 0;
        margin-bottom: 0;
      }

      @include shortest-desktop {
        display: none;
      }

      .slide {
        @include hero-grid;
        flex-direction: column;

        @include phone {
          padding-left: $horizontal-margin-phone / 2;
          margin-right: 0;
          width: 100vw;
          height: auto;
        }
        &:last-child {
          margin-right: 0;
        }

        > * {
          @include desktop-and-tablet {
            margin-right: $horizontal-margin / 2;
          }
        }

        .image {
          height: 100vw;
          margin-bottom: $vert-margin / 2;
          @include phone {
            width: 100%;
          }

          @include desktop-and-tablet {
            margin-bottom: 0;
            height: 100%;
          }
        }

        &__index {
          @include soehne(10px);
          padding-left: $horizontal-margin-phone / 2;
          margin-bottom: 2em;

          @include phone {
            width: 100%;
          }

          @include desktop-and-tablet {
            padding-left: $horizontal-margin;
          }

          @include short-desktop {
            display: none;
          }
        }
      }
    }
  }

  .logo-container {
    margin-left: $horizontal-margin-phone / 2;
    margin-bottom: $vert-margin / 2;

    @supports (display: grid) {
      grid-column: 1 / 2;
      grid-row: 1 / 2;
    }

    @include phone {
      @include logo-container;
    }

    @include desktop-and-tablet {
      @include logo-container;
      align-items: center !important;
      margin-left: $horizontal-margin / 2;
      // margin-left: $horizontal-margin;
      // margin-bottom: $vert-margin;
      // align-self: flex-end;
      // @supports (display: grid) {
      //   grid-column: 1 / 2;
      //   grid-row: 3 / 4;
      // }
    }

    @include desktop {
      @include logo-container;
      align-self: flex-end;
      margin-left: 0;
      margin-bottom: 0;
    }
  }
}
