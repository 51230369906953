@import 'c/main/src/constants';

#content {
  > .outgoing-page {
    > * {
      opacity: 0;
      transition: opacity 750ms;

      .next-page-visible & {
        transition: opacity 750ms 600ms;
      }
    }

    &.no-opacity-on-header {
      @include desktop-and-tablet {
        > .winelogo-container {
          opacity: 1;

          img {
            opacity: 0;
            transition: opacity 750ms;
            .next-page-visible & {
              transition: opacity 750ms 600ms;
            }
          }
        }
      }
      @include desktop {
        > .logo-container {
          opacity: 1;
        }
      }
    }

    &.no-opacity-on-wine-image {
      @include desktop-and-tablet {
        .wine__image,
        .cart__image {
          opacity: 1;

          img {
            opacity: 0;
            transition: opacity 750ms;
            .next-page-visible & {
              transition: opacity 750ms 600ms;
            }
          }
        }
      }
    }
  }

  > .incoming-page {
    > * {
      opacity: 0;
    }
  }

  > * {
    > * {
      opacity: 1;
      transition: opacity 750ms 250ms;

      .next-page-visible & {
        transition: opacity 750ms 600ms;
      }
    }

    &.no-opacity-on-header {
      @include desktop-and-tablet {
        > .winelogo-container {
          opacity: 1;

          img {
            opacity: 0;
            transition: opacity 350ms 0;
          }
        }
      }
      @include desktop {
        > .logo-container {
          opacity: 1;
        }
      }
    }

    &.no-opacity-on-wine-image {
      @include desktop-and-tablet {
        > .wine__image,
        .cart__image {
          opacity: 1;

          img {
            opacity: 0;
            transition: opacity 350ms 0;
          }
        }
      }
    }
  }
}
