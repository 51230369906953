@import 'c/main/src/constants';

h1,
h1,
h2,
h3,
h4,
h5,
p,
a,
li,
form,
select,
label,
.accounts-box,
.main-footer {
  opacity: 0;
  transition: opacity $transition-time;
  will-change: opacity;
}

h2 {
  font-family: 'Domaine', serif;
}

.homepage .content__menu li {
  opacity: 0;
  visibility: hidden;
  h2 {
    opacity: 1;
    font-family: 'Domaine', serif;

    a {
      font-family: 'Domaine', serif;
      opacity: 1;
      color: rgba(0, 0, 0, 0);
      will-change: color;
      transition: color $transition-time $transition-time / 2;
    }
  }
}

html.font-loaded .homepage .content__menu li {
  visibility: visible;
  opacity: 1;
  h2 {
    opacity: 1;

    a {
      color: rgba(0, 0, 0, 0);
    }
  }
}

#content > section {
  opacity: 0;
  transition: opacity $transition-time;
  will-change: opacity;
}

#content > .initialised.init-error {
  opacity: 0;
}