@import "c/main/src/constants";

html {
  overflow-y: scroll; // always show vertical scrollbar on window
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow-x: hidden;
  width: 100%;

  @media screen and (min-width: getSharedVariable(PHONE_MAX) + 1px) {
    // overflow-y: scroll;
    overscroll-behavior: none;
    // background: #f6f5ef;
  }
}

*,
*::before,
*::after {
  -moz-box-sizing: inherit;
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

body {
  position: relative;
  height: 100%;
  margin: 0;
  overscroll-behavior: none;
  width: 100%;

  @media screen and (min-width: getSharedVariable(PHONE_MAX) + 1px) {
    padding: 0 !important;
  }
}

body.site-astrolabe #content {
  background: #f6f5ef;
  margin: 0;
}

ul {
  padding-left: 0;

  li {
    list-style: none;
    margin-left: 0;
  }
}

a {
  color: inherit;
  text-decoration: none;
}

.main-menu {
  position: fixed;
  top: 0;
  right: 0;
  z-index: $overlay-zindex;
  width: 100%;
  height: $sidebar-mobile-height;
  transition: opacity $transition-time;

  @include desktop-and-tablet {
    width: $sidebar-width-half;
    height: 100vh;
    transition: none;
  }

  @include desktop {
    width: $sidebar-width;
    height: 100vh;
  }

  &__sidebar {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: $olive;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    padding-right: $horizontal-margin-phone;

    @include desktop-and-tablet {
      justify-content: flex-start;
      align-items: center;
      padding-top: $vert-margin;
      padding-right: 0;
      cursor: pointer;
    }

    @include desktop {
      // align-items: flex-start;
      padding-top: $vert-margin;
      padding-right: 0;
    }
    .hamburger {
      visibility: visible;
      @include hamburger(32px, 32px, 30px, 2px, 8px, $transition-time);
      cursor: pointer;

      &:hover,
      &:focus {
        .hamburger-inner::before,
        .hamburger-inner::after {
          background-color: $black;
        }
      }
    }
  }

  .logo-container {
    display: none;
  }
}

body.site-astrolabe #content {
  @supports (display: grid) {
    display: grid;
    width: 100%;
  }

  @include desktop-and-tablet {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100vh;
    width: 100%;
  }
}

body.site-astrolabe #content > section {
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  background: $cream;

  @include mobile-grid();
  margin-top: $sidebar-mobile-height;
  margin-right: 0;

  @include desktop-and-tablet {
    background: none;

    @include tablet-grid($scroll: false);
    margin-top: 0;
    margin-right: $sidebar-width-half;
    width: calc(100% - #{$sidebar-width-half});
  }

  @include tablet-portrait {
    @include tablet-portrait-grid($scroll: false);
    margin-right: $sidebar-width-half;
    width: calc(100% - #{$sidebar-width-half});
  }

  @include desktop {
    @include desktop-grid($scroll: false);
    margin-right: $sidebar-width;
    width: calc(100% - #{$sidebar-width});
  }

  .slide__index {
    opacity: 0;
  }

  // .vineyards__mobile-photo,
  // .vineyard__mobile-photo {
  //   opacity: 0;
  // }
}

.winelogo-container {
  background: $darkblack;
  grid-column: 1 / 2;
  grid-row: 5 / 6;
  margin: $vert-margin * 2 0 $nextPageMobileHeight 0;

  @include desktop-and-tablet {
    @include full-left-container;
    margin: 0 $horizontal-margin 0 0;
    overflow: hidden;
  }

  @include tablet-portrait {
    @include top-left-container;
  }

  @include desktop {
    @include top-left-container;
  }
}

.logo-container {
  svg {
    height: 14px;
    width: auto;

    @include desktop-and-tablet() {
      height: 15px;
    }

    @include desktop {
      height: 17px;
    }
  }
}

.has-components {
  opacity: 0;
  &.initialised {
    opacity: 1;
  }
}

.mapbox-container--sidemap {
  visibility: hidden;
}

// fade in page until page css is loaded.
.region-index-page,
.vineyard-index-page,
.vineyard-detail-page,
.wine-index-page,
.wine-detail-page,
.product-detail-page,
.generic-page,
.family-page,
.sustainability-page,
.club-join-page,
.distirbutors-page,
.shoptools-page {
  opacity: 0;
  transition: opacity $transition-time 30ms;
}
