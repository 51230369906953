@import 'c/main/src/constants';
@import 'c/main/src/type';

html.font-loaded #content > section.accounts-page.initialised {
  opacity: 1;

  &.outgoing-page,
  &.incoming-page {
    opacity: 0;
  }
}

#content > section.accounts-page.initialised {
  height: auto !important;
  overflow: visible !important;
  grid-template-rows: auto !important;

  @include desktop-and-tablet {
    @include tablet-grid($scroll: true);
  }

  @include tablet {
    @supports (display: grid) {
      grid-template-columns: 2fr 3fr 1fr;
    }
  }
  @include desktop {
    @include desktop-grid($scroll: true);

    @include wide-desktop {
      @supports (display: grid) {
        grid-template-columns: minmax(420px, 36.66vw) minmax(500px, 3.5fr);
      }
    }
  }

  .accounts {
    &__image {
      display: none;

      @include desktop-and-tablet {
        margin: 0 $horizontal-margin $vert-margin / 2 0;
        background: $darkblack;
        grid-row: 1 / 2;
        grid-column: 1 / 2;
        margin-right: $horizontal-margin;
        margin-bottom: 0;
        @include full-left-container();
        height: 100vh !important;
        position: sticky;
        top: 0;
      }

      @include desktop {
        height: 100vh;
        position: sticky;
        top: 0;
      }

      @include desktop {
        @include wide-desktop {
          margin-right: 5 * $horizontal-margin;
        }
      }

      .image {
        width: 100%;
        height: 100%;

        img {
          object-fit: contain;
          object-position: center center;
        }
      }
    }

    &__content {
      grid-row: 1 / 3;
      grid-column: 1 / -1;
      margin-top: $vert-margin;
      //   @include soehne($body-font-min);
      margin-left: $horizontal-margin-phone;
      margin-right: $horizontal-margin-phone;

      @include desktop-and-tablet {
        margin-left: $horizontal-margin;
        margin-right: $horizontal-margin;
        @include full-large-container($scroll: true);
        grid-row: 1 / -1;
      }

      @include desktop {
        @include full-right-container($scroll: true);
        grid-row: 1 / -1;
      }

      h2 {
        @include tiempos(26px);
        text-transform: capitalize;
        margin-top: 2em;
      }
    }

    &__container {
      margin-bottom: 3 * $vert-margin;

      .form-row {
        display: flex;
        flex-wrap: wrap;
      }

      .select-wrap,
      .input-wrap {
        width: 100%;
        display: block;

        input {
          width: 100%;
        }

        select {
          @include phone {
            width: 135%;
          }
        }
      }

      .button {
          width: 100%;
          max-width: 400px;
        @include desktop-and-tablet {
          width: 400px;
        }
      }
    }

    &__detail {
      @include tablet {
        max-width: 400px;
      }
      @include desktop {
        max-width: 600px;
      }
    }
  }

  .form-field {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-bottom: 1em;

    .errorlist {
      // flex-basis: 100%;
      color: $red;
    }

    label {
      // flex-basis: 20%;
      min-width: 160px;
      margin-bottom: 0.5em;
    }

    .input-wrap,
    .select-wrap {
      // flex-basis: 50%;
      width: 100%;
      max-width: 400px;

      input,
      select,
      textarea {
        width: 100%;
        margin: 0;
      }

      input::placeholder {
        opacity: 0.5;
      }
    }

    .checkbox__container {
      @include desktop {
        min-width: 160px;
      }

      + label {
        width: 100%;
        max-width: 400px;
      }
    }
  }

  .logo-container {
    text-align: right;
    margin-right: 0;
    margin-bottom: $vert-margin;
    margin-top: $vert-margin;
  }
}
