@import 'c/main/src/constants';
@import 'c/main/src/type';

.next-page {
  position: absolute;
  bottom: 0;
  left: 0;
  min-height: $nextPageMobileHeight;
  width: 100%;
  background: $cream;
  overflow:hidden;
  margin: 0!important;
  text-align: center;

  @include phone {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: $horizontal-margin-phone / 2;
    padding-right: $horizontal-margin-phone / 2;
  }
  @include desktop-and-tablet {
    background: $cream;
    visibility: hidden;
    // position: absolute;
    display: flex;
    position: fixed;
    text-align: center;
    right: calc(#{$sidebar-width-half} - #{$nextPageWidth});
    left: unset;
    top: 0;
    height: 100vh;
    width: $nextPageWidth;
    // box-shadow: inset 2px 0px 11px 0 rgba(198,198,198,0.50);
    background: linear-gradient(to right, #eee 0, $cream 11px, $cream 100%);
  }

  @include desktop {
    right: calc(#{$sidebar-width} - #{$nextPageWidth});
  }

  &__wrap {
    width: 100%;
    position: relative;
  }
  &__title {
    
    @include phone {
      transform: translateY(100%);
      transition: transform $transition-time;
    }
    @include desktop-and-tablet {
      transform: rotate(90deg);
      transform-origin: center;
      white-space: nowrap;
    }

    h2 {
      
      margin: 0;
      @include desktop-and-tablet {
        margin: 0.65em;
        @include domaine(44px, 50px * $min-font-ratio, 72px);
      }
    }
  }

  &--previsible {
    .next-page__title {
      @include phone {
        transform: translateY(0);
      }
    }
  }

  &--visible {
    visibility: visible;
  }
}
