@import 'c/main/lib/mixins';
@import 'c/main/src/constants';
@import 'c/main/src/type';

@mixin clear-input-style {
  background: none;
  border: 0;
  outline: 0;
  border-radius: 0;
  box-shadow: none;
  text-indent: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

form {
  &.autosubmit {
    html.js & {
      [type='submit'] {
        display: none;
      }
    }
  }
}

.remove {
  form {
    &.autosubmit {
      html.js & {
        [type='submit'] {
          display: initial;
        }
      }
    }
  }
}

button,
input,
select,
textarea {
  font-size: inherit;
  font-weight: inherit;
  text-align: inherit;
  color: inherit;
}

button,
textarea,
select {
  padding: 1em;
}

select,
button,
textarea {
  @include clear-input-style;
  @include soehne($body-font-min);
  padding: 1em 1em 1em;
  border: 1px solid black;
}

button,
select,
input[type='button'],
input[type='checkbox'],
input[type='radio'],
input[type='reset'],
input[type='submit'] {
  cursor: pointer;

  &:disabled {
    cursor: default;
  }
}

textarea {
  max-width: 100%;
}

input {
  @include placeholder {
    opacity: 1;
    color: inherit;
  }

  &[type='button'],
  &[type='color'],
  &[type='date'],
  &[type='datetime-local'],
  &[type='email'],
  &[type='file'],
  &[type='image'],
  &[type='month'],
  &[type='number'],
  &[type='password'],
  &[type='reset'],
  &[type='search'],
  &[type='submit'],
  &[type='tel'],
  &[type='text'],
  &[type='time'],
  &[type='url'],
  &[type='week'] {
    @include clear-input-style;
    padding: 1em;
  }

  &[type='number'] {
    // -moz-appearance: none doesn't remove spinners
    -moz-appearance: textfield;
  }

  &[type='color'],
  &[type='date'],
  &[type='datetime-local'],
  &[type='email'],
  &[type='month'],
  &[type='number'],
  &[type='password'],
  &[type='reset'],
  &[type='search'],
  &[type='tel'],
  &[type='text'],
  &[type='time'],
  &[type='url'],
  &[type='week'] {
    border: 1px solid $black;
    border-radius: 0;
    margin-left: 1ch;
    margin-right: 1ch;
    @include soehne($body-font-min);
    padding: 1em 1em 1em;
  }
}

select {
  border-radius: 0;
  background-image: url('/static/i/dropdown.svg');
  background-position: right 1em top 50%;
  background-size: 5px auto;
  background-repeat: no-repeat;
  padding-right: 30px;

  @include phone {
    font-size: 16px;
    transform: scale(0.75);
    transform-origin: center left;
    margin: -0.33em;
  }
}

.checkbox-wrap,
.radio-wrap .select-wrap,
.input-wrap {
  display: inline-block;
}

.checkbox-wrap,
.radio-wrap {
  position: relative;
  cursor: pointer;

  &.disabled {
    cursor: default;
  }

  input[type='checkbox'],
  input[type='radio'] {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;

    html.js & {
      @include clear-input-style;
      opacity: 0.01;
    }
  }
}

.radio-wrap.checked,
input[type='radio']:checked {
  cursor: default;
}

.select-wrap {
  position: relative;
  cursor: pointer;

  &.disabled {
    cursor: default;
  }

  select {
    padding-right: 1.5em;
    width: 100%;

    &:-ms-expand {
      display: none;
    }
  }
}

label[for] {
  cursor: pointer;
}

.checkbox__container + label {
  &::before {
    content: ('');
    width: 20px;
    height: 20px;
    border: 1px solid $black;
    background: $black;
    display: inline-block;
    margin-right: 10px;
    vertical-align: middle;
  }
}
.checkbox__container.checked + label {
  &::before {
    background: none;
  }
}

textarea {
  @include soehne($body-font-min);
  border: 1px solid $black;
}

input[name='quantity'] {
  width: calc(2em + 3ch);
  text-align: center;
}

.button,
input[type='submit'].button {
  padding: 1em 4em;
  background: $black;
  border: 1px solid $black;
  text-align: center;
  color: $cream;
  text-transform: uppercase;
  @include soehne($body-font-min);
  letter-spacing: 0.5px;
  display: inline-block;
  margin: 1em 0;
  &:hover,
  &:active,
  &.active {
    background: $cream;
    color: $black;
  }
}

input[type='number'] {
  font-variant-numeric: tabular-nums;
}
