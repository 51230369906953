.image {
  position: relative;
  overflow: hidden;

  &.blank {
    picture,
    img {
      visibility: hidden;
    }
  }

  &.centered {
    &.restrict-width,
    &.svg-fill-width {
      picture,
      img {
        top: 50%;
        transform: translateY(-50%);
      }
    }

    &.restrict-height,
    &.svg-fill-height {
      picture,
      img {
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  .aspect-maintainer {
    height: 0;
    position: relative;

    picture,
    img {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }

  // We can't simply use width: auto; height: auto; max-width: 100%;
  // max-height: 100%; because some versions of Firefox won't display the
  // image.
  &.restrict-width,
  &.svg-fill-width {
    picture,
    img {
      width: 100%;
      top: 0;
      left: 0;
      right: 0;
      height: auto;
    }
  }
  &.restrict-height,
  &.svg-fill-height {
    picture,
    img {
      height: 100%;
      left: 0;
      top: 0;
      bottom: 0;
      width: auto;
    }
  }

  &.no-padding {
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;

    img {
      object-fit: cover;
      object-position: center center;
      width: 100%;
      height: 100%;
      flex: 0 0 auto;
      position: absolute;
      top: unset;
      left: unset;
      right: unset;
      bottom: unset;
    }
  }

  .lazyload {
    opacity: 0;
    transition: opacity 150ms;
    visibility: hidden;
    color: rgba(0,0,0,0);

    &.lazyloaded {
      opacity: 1;
      visibility: visible;

      img {
        opacity: 1;
      }
    }

    img {
      opacity: 0;
      transition: opacity 150ms 200ms;
    }
  }
}
