@import 'c/main/src/constants';
@import 'c/main/src/type';

.vineyard-index-page.initialised {
  opacity: 1;

  .winelogo__container-link,
  .logo-container,
  .vineyards-container {
    @include phone {
      margin: 0 $horizontal-margin-phone / 2;
    }
  }

  .winelogo-container {
    margin: $vert-margin * 2 0 $nextPageMobileHeight 0;

    @include desktop-and-tablet {
      @include full-left-container;
      margin: 0 $horizontal-margin 0 0;
    }

    @include desktop {
      @include top-left-container;
    }

    &__link {
      margin: $vert-margin 15% 0 50%;
      @include desktop-and-tablet {
        margin: $vert-margin / 2 $horizontal-margin 0 0;
        display: none;
      }

      @include desktop {
        display: block;
      }
    }
  }

  .vineyards-container {
    grid-row: 2 / 3;
    margin-right: $horizontal-margin-phone / 2;
    background: $cream;

    @include desktop-and-tablet {
      @include top-right-container;
      margin-right: $horizontal-margin;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    @include desktop {
      @include top-right-container;
      position: initial;
      display: block;
    }

    h1 {
      text-align: center;

      @include desktop-and-tablet {
        position: absolute;
        bottom: -3rem;
        left: 0;
        width: 50%;
        margin-bottom: 0;
        text-align: left;
      }

      @include desktop {
        margin: 0.5em $horizontal-margin / 2 0.5em 0;
        flex-basis: calc(50% - #{$horizontal-margin / 2});
        position: initial;
        width: 100%;
        text-align: center;
      }
    }

    .vineyards {
      &__mobile-photo {
        width: 50%;
        order: 1;
        @include desktop-and-tablet {
          display: none;
        }

        img {
          width: 100%;
          height: auto;
        }
      }

      &__list {
        text-align: center;
        margin: $vert-margin * 2 0;
        @include desktop-and-tablet {
          column-count: 2;
          margin: 0;
        }
      }
    }

    .region-link {
      text-align: center;
      margin: 0 0 1em 0;
    }

    h3 {
      margin: 0;
      @include phone {
        @include domaine(36px);
      }

      &.half {
        @include phone {
          padding-bottom: 0.5em;
        }
      }
    }
  }

  .vineyardimages-container {
    grid-row: 3 / 4;
    margin: 0;
    padding-bottom: $vert-margin;

    @include desktop-and-tablet {
      @include bottom-right-container;
      margin-right: 0;
      margin-bottom: $vert-margin;
      padding-bottom: 0;
    }

    @include desktop {
      @include bottom-full-container;
      height: 100%;
      margin-right: 0;
      margin-bottom: 0;
    }

    .scroll.carousel .slide {
      @include seven-image-grid;

      @include phone {
        height: 140vw;
        width: 90vw;
      }

      &__index {
        @include soehne(10px);
        padding-left: $horizontal-margin-phone / 2;
        @include phone {
          margin-right: 0;
        }

        @include desktop-and-tablet {
          padding-left: $horizontal-margin;
        }
      }

      &__vineyard {
        margin-left: 3ch;

        @include desktop-and-tablet {
          visibility: hidden;
        }
      }

      &:hover {
        .slide__vineyard {
          visibility: visible;
        }
      }

      .image {
        height: auto;
        @include phone {
          margin-right: 0;
        }

        @include desktop-and-tablet {
          height: auto;
        }
      }

      .image + .image {
        width: 100%;
        height: auto;
        margin-top: $vert-margin / 2;
        @include desktop-and-tablet {
          margin-top: 0;
          height: auto;
          width: auto;
        }
      }
    }
  }

  .logo-container {
    @include logo-container;
  }
}

.vineyard-detail-page.initialised {
  opacity: 1;

  .winelogo__container-link,
  .logo-container,
  .vineyard-container  {
    @include phone {
      margin: 0 $horizontal-margin-phone / 2;
    }
  }

  .winelogo-container {
    margin: $vert-margin * 2 0 $nextPageMobileHeight 0;

    @include desktop-and-tablet {
      @include full-left-container;
      margin: 0 $horizontal-margin 0 0;
    }

    @include desktop {
      @include top-left-container;
    }
  }

  .vineyard-container {
    grid-row: 2 / 3;
    margin-right: $horizontal-margin-phone / 2;
    background: $cream;

    @include desktop-and-tablet {
      @include top-right-container;
      margin-right: $horizontal-margin;
      position: relative;
      z-index: 1;
    }

    @include desktop {
      @include top-right-container;
      display: flex;
      flex-wrap: wrap;
      position: initial;
    }

    @include tablet {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-bottom: $vert-margin * 4;
    }

    > * {
      @include desktop {
        flex-basis: calc(50% - #{$horizontal-margin / 2});
      }
    }

    .vineyard__mobile-photo {
      width: 50%;
      margin-right: 50%;
      @include desktop-and-tablet {
        display: none;
      }
    }

    .vineyard__title {
      text-align: center;
      margin: 1em 0;
      // white-space: nowrap;

      @include desktop-and-tablet {
        position: absolute;
        bottom: -3rem;
        left: 0;
        width: 65%;

        text-align: left;
      }

      @include desktop {
        margin: 1em $horizontal-margin / 2 1em 0;
        flex-basis: calc(50% - #{$horizontal-margin / 2});
        position: initial;
        width: 100%;
        text-align: center;
      }

      @include tablet-portrait {
        margin-bottom: calc(18px + #{$vert-margin});
        width: calc(100% - #{$horizontal-margin});
      }

      h1 {
        @include desktop-and-tablet {
          margin-bottom: 0;
        }
        @include desktop {
          max-width: 350px;
          text-align: center;
          margin-left: auto;
          margin-right: auto;
        }
      }
    }

    .vineyard__detail {
      margin-bottom: $vert-margin;

      @include desktop-and-tablet {
        margin-bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-self: flex-end;

        @include wide-desktop {
          margin-right: 22.5%;
        }

        &.active {
          display: none;
        }

        p {
          margin: 0.5em 0;
        }
      }

      @include tablet {
        margin-right: $horizontal-margin;
        margin-bottom: $vert-margin / 2;
        @include tiempos(10px);
        justify-content: flex-start;
      }

      @include tablet-portrait {
        margin-right: 0;
      }

      @include desktop {
        @include wide-desktop {
          margin-right: 0%;
        }
      }

      @include short-desktop {
        @include tiempos(10px);
      }

      @include shortest-desktop {
        display: none;
      }
    }

    

    .vineyard__notes {
      @include tiempos(10px);
      margin-left: 50%;
      margin-top: 1em;

      @include tablet {
        float: left;
        width: 50%;
        margin-bottom: $vert-margin;
      }

      @include desktop-and-tablet {
        float: none;
        width: 100%;
        margin-left: 0;
        display: none;
        align-content: flex-end;
        flex-wrap: wrap;
        margin-top: $vert-margin;

        &.active {
          display: flex;
        }
      }

      @include desktop {
        height: calc(100% - 12em);
        margin-bottom: 0;
        @include tiempos(12px);

        &.active {
          display: flex;
        }
      }

      @include short-desktop {
        display: none;

        &.active {
          display: none;
        }
      }

      &--left_col {
        flex: 1 1 50%;
        padding-right: $horizontal-margin;
      }

      &--right_col {
        flex: 1 1 50%;
        padding-right: $horizontal-margin;
      }

      .load-map {
        outline: none;
        border: none;
        margin: 0;
        padding: 0;
        background: none;
        cursor: pointer;
        color: $black;
        @include tiempos(10px);
        display: block;

        @include desktop {
          @include tiempos(12px);
        }
      }

      .vineyard__map-link {
        flex-basis: 100%;
      }

      .vineyard__map-link:hover {
        .load-map {
          opacity: $hover-opacity;
        }
      }

      p:first-child {
        margin-top: 0;
      }
    }

    .vineyard__toggle {
      display: none;
      @include desktop-and-tablet {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        width: 100px;
        float: left;

        @include wide-desktop {
          margin-top: 1em;
        }
      }

      @include short-desktop {
        display: none;
      }

      @include tablet {
        margin-left: 50%;
        position: absolute;
        bottom: $vert-margin * 4;
        left: 0;
      }

      @include tablet-portrait-skinny {
        margin-left: 0;
        bottom: $vert-margin * 5;
      }

      .toggle-notes {
        outline: none;
        border: none;
        margin: 0;
        padding: 0;
        background: none;
        cursor: pointer;
        color: $black;
        @include tiempos(10px);
        display: block;
        line-height: 1.34;

        &:hover {
          opacity: $hover-opacity;
        }

        &.active {
          opacity: $hover-opacity;
        }
      }
    }

    .vineyard__wines-detail {
      margin: 1em 0;
    }

    .vineyard__wines-all a:hover {
      opacity: 1;
    }
    .vineyard__wines-all a:hover div {
        opacity: $hover-opacity;
    }
  }

  .vineyard__wines {
    @include tiempos(10px);
    float: left;
    width: 50%;
    margin-left: 50%;
    margin-bottom: 1em;
    margin-top: 3em;

    @include desktop-and-tablet {
      margin-left: 0;
      margin-bottom: 0;
      margin-top: 0;

      @include wide-desktop {
        display: none;
      }
    }

    @include desktop {
      float: none;
      width: 100%;
      margin-bottom: 0;
      margin-right: $horizontal-margin / 2;
      margin-left: 0;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      height: auto;
    }

    @include tablet {
      @include wide-desktop {
        display: block;
      }
    }

    @include short-desktop {
      display: none;
    }

    @include tablet-portrait-skinny {
      display: none;
    }

    &.mobile-only {
      transition: opacity $transition-time * 2!important;
      opacity: 0!important;

      &.active {
        opacity: 1!important;
      }
      @include desktop-and-tablet {
        display:none;
      }
    }
    &.desktop-only {
      @include phone {
        display: none;
      }
    }
  }

  .vineyardimages-container {
    grid-row: 3 / 4;
    margin: 0;
    padding-bottom: $vert-margin;
    margin-top: $vert-margin;

    @include desktop-and-tablet {
      @include bottom-right-container;
      margin-right: 0;
      margin-bottom: $vert-margin;
      margin-top: 0;
      padding-bottom: 0;
    }

    @include desktop {
      @include bottom-full-container;
      height: 100%;
      margin-right: 0;
    }
    .scroll.carousel .slide {
      height: 135vw;
      width: 90vw;

      @include desktop-and-tablet {
        height: auto;
        width: 45vmin;
        margin-right: $horizontal-margin / 2;
      }

      .image {
        height: 100vw;
        width: auto;

        @include phone {
          margin-right: 0;
        }

        @include desktop-and-tablet {
          height: 100%;
          width: auto;
        }

        &:first-child {
          margin-bottom: 0;
          @include phone {
            margin-right: 0;
          }
        }
      }

      .image + .image {
        width: 100%;
        height: auto;
        margin-top: $vert-margin / 2;
        @include desktop-and-tablet {
          margin-top: 0;
          height: auto;
          width: auto;
        }
      }

      .mobile-only .image {
        width: calc(50% - #{$horizontal-margin-phone / 2});
        margin-top: $vert-margin / 2;
        height: calc(40vw - #{$horizontal-margin-phone});
        @include desktop-and-tablet {
          display: none;
        }
      }

      &:nth-child(n + 6) {
        @include phone {
          display: none;
        }
      }
    }
    .scroll.carousel .slide:first-child {
      @include seven-image-grid(false);
      width: calc(100vw - #{$horizontal-margin-phone});
      margin-right: 0;
      padding-bottom: 0;
      @include phone {
        display: none;
      }

      @include desktop-and-tablet {
        width: unset;
        margin-right: 0;
      }
      .image {
        width: auto;

        @include desktop-and-tablet {
          height: auto;
          width: unset;
        }

        &:first-child {
          margin-bottom: 0;
        }
      }
    }
    .slide:last-child {
      margin-right: 0;
    }
  }

  .logo-container {
    @include logo-container;
  }
}

#content > .vineyard-detail-page {
  @include shortest-desktop() {
    grid-template-rows: $vert-margin $h1-container-height 1fr;
  }
  &.content-enter {
    > * {
      opacity: 1d;
    }
  }

  &.content-enter-active {
    > * {
      opacity: 0;
      transition: opacity $transition-time;
    }
  }

  &.content-enter-done {
    > * {
      opacity: 0;
    }
  }

  &.content-exit {
    > * {
      opacity: 0;
    }
  }

  &.content-exit-active {
    > * {
      opacity: 1;
      transition: opacity $transition-time;
    }
  }

  &.content-exit-done {
    > * {
      opacity: 1;
    }
  }

  .map-enter {
    visibility: visible;
    opacity: 0;
    z-index: -1;
  }

  .map-enter-active {
    visibility: visible;
    opacity: 1;
    transition: opacity $transition-time;
    z-index: 1;
  }

  .map-enter-done {
    visibility: visible;
    opacity: 1;
    z-index: 1;
  }

  .map-exit {
    visibility: visible;
    opacity: 1;
    z-index: 1;
  }

  .map-exit-active {
    visibility: visible;
    opacity: 0;
    transition: opacity $transition-time;
    z-index: 1;
  }

  .map-exit-done {
    visibility: visible;
    opacity: 0;
    z-index: -1;
  }
  &.outgoing-page {
    .map-enter-done {
      transition: opacity $transition-time;
      opacity: 0;
      z-index: 1;
    }
  }
}
