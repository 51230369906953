@import 'c/main/src/constants';

.scroll-container {
  width: 100vw;
  height: 130vh;

  @include desktop-and-tablet {
    width: 100%;
    position: initial;
    height: 100%;
    overflow: hidden;
  }

  .scroll,
  .scroll-listener {
    position: sticky;
    top: calc(100vh - 150vw);

    @include desktop-and-tablet {
      position: initial;
      top: unset;
      display: block;
      height: 100%;
    }

    > .slide {
      @include desktop-and-tablet {
        height: 100%;
      }
    }
  }

  .scroll.carousel {
    @include phone {
      padding-bottom: $vert-margin * 2;
    }
    &:after {
      content: 'flickity';

      @include desktop-and-tablet {
        content: '';
        display: none;
      }
    }
    .carousel-cell {
      @include phone {
        width: 90%;
        height: 90vw;
        padding-left: $horizontal-margin-phone / 2;
        overflow-y: hidden;
      }
    }
  }
}

.spacer {
  position: 'relative';
  pointer-events: none;
  z-index: 1;
}
