@import 'c/main/src/constants';
@import 'c/main/src/type';

.region-index-page.initialised {
  opacity: 1;

  .winelogo__container-link,
  .logo-container,
  .region__content {
    @include phone {
      margin: 0 $horizontal-margin-phone / 2;
    }
  }

  .winelogo-container {
    @include phone {
      margin: $vert-margin * 2 0 $nextPageMobileHeight 0;
    }
    &__link {
      @include desktop-and-tablet {
        display: none;
      }

      @include tablet-portrait {
        display: block;
      }

      @include desktop {
        display: block;
      }
    }
  }

  .region {
    &__content {
      grid-row: 2 / 3;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-between;
      background: $cream;

      @include desktop-and-tablet {
        @include top-right-container;
        margin-right: $horizontal-margin;
      }

      @include tablet-portrait {
        @include top-right-container;
        display: flex;
      }

      @include desktop {
        @include top-right-container;
        grid-row: 1 / 3 !important;
        display: flex;
        max-width: 1200px;
        margin-right: auto;
      }

      h1 {
        margin: 1em 0 10px 0;
        order: 5;
        text-align: center;

        @media screen and (max-width: 360px) {
          @include domaine(52px);
        }

        @include desktop-and-tablet {
          order: unset;
          margin-top: auto;
          margin-bottom: -3rem;
          text-align: left;
        }

        @include desktop {
          margin-bottom: 0;
        }
      }

      h1.region__mobile-title {
        text-align: center;
        margin: 1em 0;
        order: 2;

        @include desktop-and-tablet {
          display: none;
        }
      }

      .load-map {
        outline: none;
        border: none;
        margin: 0;
        padding: 0;
        background: none;
        cursor: pointer;
        color: $black;
      }
    }

    &__mobile-photo {
      width: 50%;
      order: 1;
      @include desktop-and-tablet {
        display: none;
      }
    }

    &__detail {
      order: 3;

      @include desktop-and-tablet {
        order: unset;
        margin-top: $vert-margin;
        max-width: 1000px;
        margin-right: $horizontal-margin;
      }

      @include tablet-portrait {
        @include tiempos(10px);
      }

      &.active {
        display: none;
      }
      p:first-child {
        margin-top: 0;
      }
    }

    &__images {
      grid-row: 3 / 4;
      padding-bottom: $vert-margin;
      @include phone {
        margin: 0;
      }

      @include desktop-and-tablet {
        @include bottom-right-container;
        margin-bottom: 0;
        padding-bottom: 0;
      }

      @include tablet-portrait {
        margin-bottom: 0;
        @include bottom-full-container;
        height: 100%;
      }

      @include desktop {
        margin-bottom: 0;
        @include bottom-full-container;
        height: 100%;
      }

      @include shortest-desktop() {
        display: none;
      }

      .scroll.carousel .slide {
        @include four-image-grid;
        @include phone {
          height: 150vw;
          margin-bottom: $vert-margin / 2;
          // overflow-y: hidden;
          width: 90vw;
        }

        @include desktop-and-tablet {
          margin-right: $horizontal-margin / 2;
        }

        @include short-desktop {
          grid-template-rows: 0 2fr 2fr 1fr;
        }

        &__index {
          @include soehne(10px);
          padding-left: $horizontal-margin-phone / 2;

          @include desktop-and-tablet {
            padding-left: $horizontal-margin / 2;
          }

          @include short-desktop {
            display: none !important;
          }
        }

        &:nth-child(2n) {
          @include single-image-grid;

          @include short-desktop {
            grid-template-rows: 0 1fr;
          }
        }

        &:last-child {
          margin-right: 0;
          padding-right: $horizontal-margin-phone / 2;

          @include desktop-and-tablet {
            padding-right: $horizontal-margin / 2;
          }
        }

        .image {
          height: calc(90vw + 3rem);
          @include phone {
            margin-right: 0;
          }

          @include desktop-and-tablet {
            height: auto;
          }
        }

        .image + .image {
          width: 100%;
          height: auto;
          margin-top: $vert-margin / 2;
          @include phone {
            margin-bottom: 0;
            img {
              padding-top: $vert-margin / 2;
            }
          }
          @include desktop-and-tablet {
            margin-top: 0;
            height: auto;
            width: auto;
          }
        }
      }
    }

    &__subregion {
      margin: auto 0;
      order: 4;

      @include desktop-and-tablet {
        order: unset;
        display: block;
      }

      @include tablet-portrait {
        margin: 1em 0 0;
      }

      @include tablet {
        display: block;
      }

      @include wide-desktop {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
      }

      @include desktop {
        display: block;
      }

      &--detail {
        @include tiempos(
          $body-font-min * $min-font-ratio,
          $body-font-min * $min-font-ratio,
          $body-font-max * $min-font-ratio
        );

        @include desktop-and-tablet {
          // max-width: 400px;
          flex-basis: 70%;
          margin-left: auto;
          margin-right: 0;
          margin-top: 0;

          p {
            margin-top: 0;
          }
        }

        @include wide-desktop {
          // max-width: 450px;
          // margin-right: auto;
          // margin-left: 150px;
        }

        @include desktop {
          // display: block;
          @include tiempos(
            $body-font-min * $min-font-ratio,
            $body-font-min * $min-font-ratio,
            $body-font-max * $min-font-ratio
          );

          position: initial;
          // width: 100%;
          // margin-left: 0;
          p {
            margin-top: 1em;
          }
        }
      }
    }

    &__subregion-wrap {
      
      margin-top: $vert-margin;
      padding-bottom: $vert-margin;
      order: 4;

      @include desktop-and-tablet {
        order: unset;
        display: none;
      }
      &.active {
        display: block;

        @include desktop-and-tablet {
          display: flex;
          flex-direction: column;
        }
      }
    }

    &__subregion-container {
      display: flex;
      flex-direction: column;
      margin-left: 25%;

      @include desktop-and-tablet {
        position: relative;
        margin-left: 0;

        width: 100%;
        display: block;
      }

      @include wide-desktop {
        width: 80%;
        flex-basis: 100%;
        display: flex;
      }

      @include desktop {
        display: flex;
        flex-direction: row;
        position: initial;
      }
    }

    &__subregion-content {
      flex-basis: 33%;
      padding-right: $horizontal-margin-phone / 2;

      @include desktop-and-tablet {
        padding-right: $horizontal-margin * 2;
        display: flex;
        justify-content: space-between;
      }

      @include desktop {
        display: block;
      }
      @include tablet {
        padding-right: 0;
      }
    }

    &__subregion-title {
      margin: 2em 0;
      display: none;
      cursor: pointer;

      @include tablet-portrait {
        margin: 0;
      }

      @include desktop-and-tablet {
        display: block;
        margin: 0;
      }

      @include wide-desktop {
        width: 20%;
      }

      @include desktop {
        width: auto;
      }

      a {
        cursor: pointer;
      }

      &.active a {
        @include desktop-and-tablet {
          opacity: $hover-opacity;
        }
      }

      &--mobile-only {
        display: block;
        margin: 1em 0;

        @include desktop-and-tablet {
          display: none;
        }
      }

      &--italics {
        @include tiempos-italics(12px);
        text-transform: capitalize;
        margin: 0;

        @include desktop-and-tablet {
          // cursor: pointer;
          margin: 0.5em auto 0.5em 0;
          flex-basis: 20%;
        }

        // @include wide-desktop {
        //   margin: 0em 0 1em;
        // }

        @include desktop {
          cursor: auto;
          // margin: 0;
          text-decoration: none;
        }
      }
    }

    &__subregion-map {
      margin: 1em 0 4em;
      display: inline-block;

      @include desktop-and-tablet {
        margin: 1em 0 4em;
      }

      @include wide-desktop {
        width: 20%;
      }

      @include desktop {
        width: auto;
      }

      @include tablet {
        margin-bottom: 1em;
      }
    }
  }

  .logo-container {
    @include logo-container;
    @include desktop-and-tablet {
      @supports (display: grid) {
        grid-row: 2 / 3;
      }
    }

    @include tablet-portrait {
      align-items: flex-start;
      margin-top: -3rem;
    }
  }
}

#content > .region-index-page.initialised {
  @include shortest-desktop() {
    grid-template-rows: max-content $h1-container-height 1fr;
  }
  &.content-enter {
    > * {
      opacity: 1d;
    }
  }

  &.content-enter-active {
    > * {
      opacity: 0;
      transition: opacity $transition-time;
    }
  }

  &.content-enter-done {
    > * {
      opacity: 0;
    }
  }

  &.content-exit {
    > * {
      opacity: 0;
    }
  }

  &.content-exit-active {
    > * {
      opacity: 1;
      transition: opacity $transition-time;
    }
  }

  &.content-exit-done {
    > * {
      opacity: 1;
    }
  }

  .map-enter {
    visibility: visible;
    opacity: 0;
    z-index: -1;
  }

  .map-enter-active {
    visibility: visible;
    opacity: 1;
    transition: opacity $transition-time;
    z-index: 1;
  }

  .map-enter-done {
    visibility: visible;
    opacity: 1;
    z-index: 1;
  }

  .map-exit {
    visibility: visible;
    opacity: 1;
    z-index: 1;
  }

  .map-exit-active {
    visibility: visible;
    opacity: 0;
    transition: opacity $transition-time;
    z-index: 1;
  }

  .map-exit-done {
    visibility: visible;
    opacity: 0;
    z-index: -1;
  }
  &.outgoing-page {
    .map-enter-done {
      transition: opacity $transition-time;
      opacity: 0;
      z-index: 1;
    }
  }
}

#content > section.region-map-page {
  @include map-grid;

  .map {
    height: 100%;
    width: 100%;

    @supports (display: grid) {
      grid-template-columns: 1 / 2;
    }
  }
}
