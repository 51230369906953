a {
  cursor: pointer; // for anchors with no href

  &.current-page {
    cursor: default;
  }

  &:focus {
    outline: none;
  }
}
