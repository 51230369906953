@import 'c/main/src/constants';
@import 'c/main/src/type';

.generic-page.initialised {
  opacity: 1;
  margin-bottom: $vert-margin * 6;

  @include desktop-and-tablet {
    grid-template-rows:  1fr $logo-container-height calc(
      50vh - #{$logo-container-height}
    ) !important;
    min-height: 100vh!important;
    height: auto !important;
    margin-bottom: 0;
  }

  @include desktop {
    grid-template-rows: 1fr $logo-container-height calc(
        50vh - #{$logo-container-height}
      ) !important;
    height: auto !important;
  }

  @include short-desktop {
    grid-template-rows: 1fr $logo-container-height 0!important;
  }
  h1 {
    margin: 1em $horizontal-margin-phone / 2;

    @include desktop-and-tablet {
      margin: 1em $horizontal-margin;
    }
  }

  .content {
    margin: 0 $horizontal-margin-phone / 2;
    @include tiempos(11px);
    line-height: 1.43;

    @include desktop-and-tablet {
      margin: 67px $horizontal-margin $vert-margin 0;
      grid-row: 1 / -1;
      grid-column: 2 / 4;
      display: flex;
    }

    @include desktop {
      grid-column: 2 / 3;
    }

    p:first-child,
    p:last-child {
      margin-top: 0;
    }

    &__column1 {
      flex: 1;
      margin-right: $horizontal-margin-phone / 2;

      @include desktop-and-tablet {
        margin-right: 0;
        margin-left: $horizontal-margin * 2;
      }
    }

    &__column2 {
      flex: 1;
      @include phone {
        margin-top: 1em;
      }
      @include desktop-and-tablet {
        margin-left: $horizontal-margin * 3;
      }
    }
  }

  .scroll-container {
    margin: 0 0 $nextPageMobileHeight 0;
    width: 100%;
    display: none;

    @include desktop-and-tablet {
      @include bottom-full-container;
      margin-right: 0;
      margin-bottom: $vert-margin;
      padding-bottom: 0;
    }

    @include desktop {
      @include bottom-full-container;
      margin-right: 0;
      margin-bottom: 0;
    }

    @include short-desktop {
      display: none;
    }
  }

  .scroll {
    @include desktop-and-tablet {
      @include hero-grid;
    }

    .slide {
      @include phone {
        padding: $horizontal-margin-phone / 2;
        margin-right: 0;
        width: 100vw;
        height: auto;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .image {
      height: 100vw;
      margin-bottom: $vert-margin / 2;

      @include desktop-and-tablet {
        margin-bottom: 0;
        height: 100%;
      }
    }
  }

  .logo-container {
    @include logo-container;
    @include phone {
      grid-row: 1 / 2;
    }

    @include desktop-and-tablet {
      margin-right: $horizontal-margin;
    }
  }

  // Kind of assuming this is just the terms and conditions page only
  &.scroll-vertical {
    @include desktop-and-tablet {
      grid-template-rows: $vert-margin $logo-container-height auto!important;
      min-height: 100vh!important;
      height: auto !important;
      margin-bottom: 0;
    }
  
    @include desktop {
      grid-template-rows: $vert-margin $logo-container-height auto!important;
      height: auto !important;
    }
  
    @include short-desktop {
      grid-template-rows: 1$vert-margin $logo-container-height auto!important;
    }

    .content__column1 {
      @include tiempos(13px);

      @include desktop-and-tablet {
        @include tiempos(15px);
        margin-top: 15em;
        max-width: 380px;
      }
      @include desktop {
        max-width: 450px;
      }
    }

    .content__column2 {
      @include tablet {
        flex: 0 0 $horizontal-margin;
        margin-left: 0;
      }

      @include desktop {
        flex: 0 0 20%;
      }
    }
  }
}

.accounts-box {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
}