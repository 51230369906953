@import 'c/main/src/constants';
@import 'c/main/src/type';

html.font-loaded #content > section.subscribe-account-page,
html.font-loaded #content > section.subscribe-details-page,
html.font-loaded #content > section.subscribe-success-page,
html.font-loaded #content > section.subscribe-checkout-page {
  opacity: 1;

  &.outgoing-page,
  &.incoming-page {
    opacity: 0;
  }
}

#content > section.subscribe-account-page.initialised,
#content > section.subscribe-details-page.initialised,
#content > section.subscribe-success-page.initialised,
#content > section.subscribe-checkout-page.initialised {
  height: auto !important;
  overflow: visible !important;
  grid-template-rows: auto !important;
  position: relative;

  @include desktop-and-tablet {
    @include tablet-grid($scroll: true);
  }

  @include tablet {
    @supports (display: grid) {
      grid-template-columns: 2fr 3fr 1fr;
    }
  }
  @include desktop {
    @include desktop-grid($scroll: true);

    @include wide-desktop {
      @supports (display: grid) {
        grid-template-columns: minmax(420px, 36.66vw) minmax(500px, 3.5fr);
      }
    }
  }

  .accounts-box {
    display: none;

    &.logged-in {
      display: block;
    }
    margin-left: $horizontal-margin-phone;

    @include desktop-and-tablet {
      left: unset;
      right: 0;
      top: 0;
    }

    &__container {
      @include desktop-and-tablet {
        text-align: right;
      }
    }
  }

  .subscribe {
    &__details {
      margin-bottom: 2em;
    }
    &__image {
      display: none;

      @include desktop-and-tablet {
        margin: 0 $horizontal-margin $vert-margin / 2 0;
        background: $darkblack;
        grid-row: 1 / 2;
        grid-column: 1 / 2;
        margin-right: $horizontal-margin;
        margin-bottom: 0;
        @include full-left-container();
        height: 100vh !important;
        position: sticky;
        top: 0;
      }

      @include desktop {
        height: 100vh;
        position: sticky;
        top: 0;
      }

      @include desktop {
        @include wide-desktop {
          margin-right: 5 * $horizontal-margin;
        }
      }

      .image {
        width: 100%;
        height: 100%;

        img {
          object-fit: contain;
          object-position: center center;
        }
      }
    }

    &__content {
      grid-row: 1 / 3;
      grid-column: 1 / -1;
      margin-top: $vert-margin;
      //   @include soehne($body-font-min);
      margin-left: $horizontal-margin-phone;
      margin-right: $horizontal-margin-phone;

      @include desktop-and-tablet {
        margin-left: $horizontal-margin;
        margin-right: $horizontal-margin;
        @include full-large-container($scroll: true);
        grid-row: 1 / -1;
      }

      @include desktop {
        @include full-right-container($scroll: true);
        grid-row: 1 / -1;
      }

      h2 {
        @include tiempos(26px);
        text-transform: capitalize;
        margin-top: 2em;

        + h2 {
          margin-top: 0;
        }
      }
    }

    &__join {
      clear: both;
      h3 {
        @include tiempos(20px);
        text-transform: none;
      }
    }

    &__columns {
      display: flex;
      margin-top: 2em;

      @include tablet {
        max-width: calc(600px - #{$horizontal-margin * 3});
      }
      @include desktop {
        max-width: calc(800px - #{$horizontal-margin * 4});
      }

      h4 {
        @include tiempos(14.9px);
        font-weight: 400;
        margin-top: 0;

        @include phone {
          min-height: 3em;
        }
      }
    }

    &__column {
      flex-basis: 50%;
      padding-right: $horizontal-margin * 1;

      &:last-child {
        padding-left: $horizontal-margin * 1;
        padding-right: 0;
        border-left: 1px solid $black;
      }
    }

    &__form {
      @include tablet {
        max-width: 600px;
      }
      @include desktop {
        max-width: 800px;
      }

      h4 {
        @include tiempos($body-font-max);
        text-transform: none;
      }

      label {
        @include soehne($body-font-min);
      }

      .subscribe-form {
        &__inner {
          border-top: 1px solid $black;
          border-bottom: 1px solid $black;
          margin: $vert-margin 0;
          padding: $vert-margin 0;
          display: flex;
          flex-wrap: wrap;
          flex-direction: column;
          justify-content: flex-start;

          @include desktop-and-tablet {
            flex-direction: row;
            align-items: flex-end;
          }
        }

        &__notes {
          margin-bottom: $vert-margin;
        }

        .form-field {
          margin-right: 1ch;
          flex: 3;
          margin-bottom: 1em;

          @include desktop-and-tablet {
            margin-bottom: 0;
          }
        }

        .uncommitted_quantity,
        .quantity {
          margin-top: 2.2em;
          flex: 1;
       
          label {
            display: block;
            height: 2.2em;
            width: 6ch;
          }

          input {
            width: 6ch;
            margin: 0;
          }
        }

        .extra {
          // margin-right: $horizontal-margin-phone;
          margin-bottom: 0;
          display: inline-flex;
          flex-direction: column;
          justify-content: center;

          @include phone {
            @include tiempos(20px);
          }

          @include desktop-and-tablet {
            margin-top: 2.2em;
            margin-bottom: 1em;
            margin-right: auto;
            flex: 2;
          }
        }

        .uncommitted_product,
        .subscription-product {
          label {
            display: block;
            height: 2.2em;
          }

          select {
            min-width: 130px;
            width: 100%;

            @include desktop-and-tablet {
              min-width: 150px;
            }
          }

          .select-wrap {
            width: 100%;
          }
        }

        .wine_preferences {
          flex-basis: 100%;
          display: none;
          flex-direction: column;
          margin-top: 2em;

          margin-right: 0;

          &.active {
            display: inline-flex;
          }

          label {
            margin-bottom: 1em;
          }

          textarea {
            height: 6em;
            resize: none;
            max-width: calc(100vw - #{$horizontal-margin-phone * 2});
          }
        }

        .button {
          width: 100%;
          max-width: 300px;
          @include desktop-and-tablet {
            display: block;
          }
        }

        .text-link {
          display: block;

          width: 100%;
          max-width: 300px;
          text-align: center;

          button {
            border: none;
            width: auto;
            margin: 0 auto;
          }
        }

        .cancellation-wrap {
          .cancel-first {
            display: block;
          }

          .confirm-cancel {
            display: none;
          }
          &.active {
            .cancel-first {
              display: none;
            }
  
            .confirm-cancel {
              display: block;
            }
          }
        }
      }

      .account-form {
        label {
          display: block;
        }

        input,
        select {
          margin: 0.5em 0 1em;
          width: 100%;
          max-width: 400px;
        }

        .form-field .input-wrap {
          width: 100%;
          max-width: 400px;
        }

        .button {
          width: 100%;
          max-width: 400px;
          margin-top: $vert-margin;
        }

        .billing-address {
          .billing-address-inner {
            display: none;
            margin-top: 1em;
          }

          &.active {
            .billing-address-inner {
              display: block;
            }
          }
        }

        textarea {
          width: 100%;
          max-width: 400px;
          margin-top: 1em;
          resize: none;
        }

        .errorlist a {
          text-decoration: underline;
        }
      }

      .payment__title {
        margin-bottom: 1em;
      }

      .payment-form {
        .stripe-card-element {
          border: 1px solid $black;
          padding: 1em;
          margin: 1em 0;
          max-width: 400px;
        }

        .button {
          width: 100%;
          max-width: 400px;
          margin-top: $vert-margin;
        }
      }
    }

    &__container {
      margin-bottom: 3 * $vert-margin;

      //   .select-wrap,
      //   .input-wrap {
      //     width: 100%;
      //     display: block;

      //     input {
      //       width: 100%;
      //     }

      //     select {
      //       @include phone {
      //         width: 135%;
      //       }
      //     }
      //   }
    }

    &__detail {
      @include tablet {
        max-width: 400px;
      }
      @include desktop {
        max-width: 600px;
      }
    }
  }

  .form-field {
    // display: flex;
    // flex-direction: column;
    // flex-wrap: wrap;
    // margin-bottom: 1em;

    // .errorlist {
    //   // flex-basis: 100%;
    //   color: $red;
    // }

    // label {
    //   // flex-basis: 20%;
    //   min-width: 160px;
    //   margin-bottom: 0.5em;
    // }

    // .input-wrap,
    // .select-wrap {
    //   // flex-basis: 50%;
    //   width: 100%;
    //   max-width: 400px;

    //   input,
    //   select,
    //   textarea {
    //     width: 100%;
    //     margin: 0;
    //   }

    //   input::placeholder {
    //     opacity: 0.5;
    //   }
    // }

    // .checkbox__container {
    //   @include desktop {
    //     min-width: 160px;
    //   }

    //   + label {
    //     width: 100%;
    //     max-width: 400px;
    //   }
    // }

    .checkbox-wrap {
      input {
        opacity: 1;
        height: 20px;
        width: auto !important;
      }

      + label {
        &::before {
          content: "";
          width: 20px;
          height: 20px;
          border: 1px solid $black;
          background: none;
          display: inline-block;
          margin-right: 10px;
          vertical-align: middle;
        }
      }

      &.checked + label {
        &::before {
          background: $black;
        }
      }
    }
  }

  .logo-container {
    text-align: right;
    margin-right: 0;
    margin-bottom: $vert-margin;
    margin-top: $vert-margin;
  }
}
