@import 'c/main/src/constants';
@import 'c/main/src/type';

html.font-loaded #content > section.shoptools-page,
html.font-loaded #content > section.payment-page {
  opacity: 1;

  &.outgoing-page,
  &.incoming-page {
    opacity: 0;
  }
}

#content > section.shoptools-cart-page,
#content > section.shoptools-checkout-page,
#content > section.payment-page,
#content > section.shoptools-success-page {
  height: auto !important;
  overflow: visible !important;
  grid-template-rows: auto !important;

  @include desktop-and-tablet {
    @include tablet-grid($scroll: true);
  }

  @include tablet {
    @supports (display: grid) {
      grid-template-columns: 2fr 3fr 1fr;
    }
  }
  @include desktop {
    @include desktop-grid($scroll: true);

    @include wide-desktop {
      @supports (display: grid) {
        grid-template-columns: minmax(420px, 36.66vw) minmax(500px, 3.5fr);
      }
    }
  }

  .cart,
  .checkout,
  .payment,
  .success {
    &__image {
      display: none;

      @include desktop-and-tablet {
        margin: 0 $horizontal-margin $vert-margin / 2 0;
        background: $darkblack;
        grid-row: 1 / 2;
        grid-column: 1 / 2;
        margin-right: $horizontal-margin;
        margin-bottom: 0;
        @include full-left-container();
        height: 100vh !important;
        position: sticky;
        top: 0;
      }

      @include desktop {
        height: 100vh;
        position: sticky;
        top: 0;
      }

      @include desktop {
        @include wide-desktop {
          margin-right: 5 * $horizontal-margin;
        }
      }

      .image {
        width: 100%;
        height: 100%;

        img {
          object-fit: contain;
          object-position: center center;
        }
      }
    }

    &__content {
      grid-row: 1 / 3;
      grid-column: 1 / -1;
      margin-top: $vert-margin;
      @include soehne($body-font-min);
      margin-left: $horizontal-margin-phone;
      margin-right: $horizontal-margin-phone;

      @include desktop-and-tablet {
        margin-left: $horizontal-margin;
        margin-right: $horizontal-margin;
        @include full-large-container($scroll: true);
        grid-row: 1 / -1;
      }

      @include desktop {
        @include full-right-container($scroll: true);
        grid-row: 1 / -1;
      }

      h2 {
        @include tiempos(26px);
        text-transform: capitalize;
        margin-bottom: 2em;

        &.cart__title--left {
          @include desktop {
            width: 50%;
            float: left;
          }
        }
        &.cart__title--right {
          display: none;
          @include desktop {
            display: inline-block;
            width: 50%;
            float: left;
          }
        }
      }
    }

    &__container {
      clear: both;
      display: flex;
      flex-wrap: wrap;

      @include phone {
        margin-bottom: 3 * $vert-margin;
      }

      .cart-errors {
        flex-basis: 100%;
      }

      .checkout-item-list {
        flex-basis: 100%;

        @include desktop {
          flex-basis: 50%;
        }

        .form-row {
          margin-bottom: 2em;
        }
      }

      .cart__options {
        flex-basis: 100%;

        @include desktop {
          padding-right: 20%;
          flex-basis: 50%;
        }
      }

      .form-row {
        display: flex;
        flex-wrap: wrap;
      }

      .cell {
        // flex-basis: 25%;
        margin-right: auto;

        &.description {
          flex-basis: 100%;
        }

        &.remove {
          flex-basis: 25%;
          margin-left: 50px;
          margin-right: 0;

          @include phone {
            text-align: right;
          }
        }

        &.line-total {
          font-variant-numeric: tabular-nums;
          flex-grow: 1;
        }
      }

      .select-wrap,
      .input-wrap {
        width: 100%;
        display: block;

        input {
          width: 100%;
        }

        select {
          @include phone {
            width: 135%;
          }
        }
      }

      .subtotal {
        margin-bottom: 2em;
        margin-top: 1em;

        .subtotal-inner {
          margin-top: 0.5em;
        }

        .cart-subtotal {
          font-variant-numeric: tabular-nums;
        }
      }

      .cart__region-title {
        margin-bottom: 0.5em;
      }

      .region-selection-form {
        label {
          display: none;
        }
      }

      .shipping-option-selection-form {
        label {
          display: none;
        }
      }

      .shipping {
        margin: 1em 0 2em;

        .cart-shipping-cost {
          font-variant-numeric: tabular-nums;
        }
      }

      .cart__shipping-title {
        margin: 1em 0;
      }

      .cart__voucher-title {
        margin-bottom: 0.5em;
      }

      .voucher-form {
        margin-bottom: 2em;
        label {
          display: none;
        }

        input {
          margin-left: 0;
        }
      }

      .total-inner {
        @include soehne($body-font-max);
        margin-top: 0.5em;

        .cart-total {
          font-variant-numeric: tabular-nums;
        }
      }

      .checkout-buttons {
        display: block;
        text-align: center;

        .button {
          margin: 2em 0;
          width: 100%;
        }
      }

      .checkout__continue-shopping {
        //   padding: 1em 0 0;
      }
    }
  }
  .registration-form,
  .checkout-form {
    margin-bottom: 10em;

    .is_eighteen .checkbox__container + label {
      &::before {
        background: none;
      }
    }

    .is_eighteen .checkbox__container.checked + label {
      &::before {
        background: $black;
      }
    }

    .save_details .checkbox__container + label {
      &::before {
        background: none;
      }
    }

    .save_details .checkbox__container.checked + label {
      &::before {
        background: $black;
      }
    }

    .billing-address {
      .billing-address-inner {
        display: none;
      }

      &.active {
        .billing-address-inner {
          display: block;
        }
      }
    }

    .subscribe {
      margin-bottom: 3em;
      .checkbox__container + label {
        &::before {
          background: none;
        }
      }

      .checkbox__container.checked + label {
        &::before {
          background: $black;
        }
      }
    }

    .user-form {
      display: none;

      &.active {
        display: block;
      }
    }
    .form-field {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      margin-bottom: 1em;

      .errorlist {
        // flex-basis: 100%;
        color: $red;
      }

      label {
        // flex-basis: 20%;
        min-width: 160px;
        margin-bottom: 0.5em;
      }

      .input-wrap,
      .select-wrap {
        // flex-basis: 50%;
        width: 100%;
        max-width: 400px;

        input,
        select,
        textarea {
          width: 100%;
          margin: 0;
        }
      }

      .checkbox__container {
        @include desktop {
          min-width: 160px;
        }

        + label {
          width: 100%;
          max-width: 400px;
        }
      }
    }

    .form-field.address {
      margin-bottom: 0;
    }

    #result {
      margin-bottom: 0.5em;
    }

    .checkout-buttons {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      @include desktop {
        justify-content: flex-start;
      }
      &:before {
        @include desktop {
          content: ('');
          flex-basis: 20%;
          min-width: 160px;
        }
      }
      &:after {
        @include desktop {
          content: ('');
          flex-basis: 20%;
          min-width: 160px;
          order: 3;
        }
      }
      .button {
        flex-basis: 100%;
        max-width: 400px;
        @include desktop {
          flex-basis: 50%;

          page-break-after: always;
          break-after: always;
        }
      }
      .back {
        flex-basis: 100%;
        text-align: center;
        @include desktop {
          margin-right: 50%;
          flex-basis: 50%;
          width: 100%;
          max-width: 400px;
          order: 4;
        }
      }
    }
  }

  .payment {
    &__title {
      @include tiempos($body-font-max);
      margin-bottom: 2em;
    }

    &__form {
      max-width: calc(100vw - #{$horizontal-margin-phone * 2});

      @include tablet {
        width: 400px;
        margin-left: auto;
      }

      @include desktop {
        width: 400px;
        margin-left: auto;
        margin-right: 0;
      }

      @include wide-desktop {
        width: 400px;
        margin-left: 50%;
        margin-right: auto;

        @include tablet {
          margin-left: auto;
          margin-right: 0;
        }
      }

      .form-row {
        margin-bottom: 2em;

        label {
          display: block;
          margin-bottom: 1em;
        }
      }

      .stripe-card-element {
        border: 1px solid $black;
        padding: 1em;
      }

      .button {
        width: 100%;
      }
    }
  }

  .logo-container {
    text-align: right;
    margin-right: 0;
    margin-bottom: $vert-margin;
    margin-top: $vert-margin;
  }
}

.shoptools-checkout-page {
  .user,
  .shipping-address,
  .billing-address,
  .subscribe {
    @include desktop-and-tablet {
      margin-left: 50%;
    }
  }

  .subscribe + .checkout-buttons {
    @include desktop-and-tablet {
      margin-left: 50%;
    }

    &:before {
      @include desktop-and-tablet {
        display: none;
      }
    }

    &:after {
      @include desktop-and-tablet {
        display: none;
      }
    }
  }
  .billing-address {
    h2 {
      margin: 3em 0 0;
    }
  }
}

.shoptools-success-page {
  .success__content {
    @include desktop-and-tablet {
      margin-left: 50%;
    }
  }
}

#content > section.shoptools-checkout-page.club__cart {
  .shipping-address,
  .billing-address,
  .subscribe {
    margin-left: 0;
  }

  .checkout-buttons {
    max-width: 400px;
    margin-left: 0;
  }

  .gift_message {
    display: none;
  }

  .user {
    display: none;
  }
  .club__note {
    max-width: 380px;
    @include tiempos(22px);
    margin-bottom: $vert-margin * 2;
  }

  h2 {
    @include tiempos(22px);
  }
}

.mapboxgl-ctrl-geocoder--icon-search {
  display: none;
}

.mapboxgl-ctrl-geocoder--input {
  display: none;
}

.mapboxgl-ctrl-geocoder--pin-right {
  display: none;
}

#geocoder .suggestions {
  margin-top: 0;
  padding: 0.5em;
  border: 1px solid $black;
  max-width: 400px;
}
