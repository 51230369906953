@import 'c/main/src/constants';
@import 'c/main/src/type';

.main-menu {
  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;

    @include desktop-and-tablet {
      height: 100vh;
      overflow-y: scroll;
    }

    @include short-desktop {
      min-height: 900px;
    }
  }

  &__main-nav {
    $nav-width: 650px;
    position: absolute;
    top: $sidebar-mobile-height;
    left: 0;
    min-height: calc(100vh - #{$sidebar-mobile-height});
    width: 100%;
    background: $olive;
    transform: translateY(-103%);
    display: none;
    color: $black;
    height: calc(100vh - #{$sidebar-mobile-height});
    @include scrollable;

    @include desktop-and-tablet {
      transform: translateX(103%);
      left: -$nav-width;
      height: 100%;
      width: calc(#{$nav-width} + #{$sidebar-width-half} / 2);
      top: 0;
      overflow: hidden;
    }

    @include desktop {
      width: calc(#{$nav-width} + #{$sidebar-width} / 2);
    }

    @include short-desktop {
      overflow: scroll;
    }

    body.menu-open & {
      transform: translateX(0);
    }

    .active-enter & {
      display: block;
      transform: translateY(-103%);
      @include desktop-and-tablet {
        transform: translateX(103%);
      }
    }

    .active-enter-active & {
      display: block;
      @include transition(transform, $transition-time);
      transform: translateY(0%);

      @include desktop-and-tablet {
        transform: translateX(0%);
        @include transition(
          transform,
          $transition-time,
          cubic-bezier(0.4, 0.18, 0.16, 1.27)
        );
      }
    }

    .active-enter-done & {
      display: block;
      transform: translateY(0%);

      @include desktop-and-tablet {
        transform: translateX(0%);
      }
    }

    .active-exit & {
      display: block;
      transform: translateY(0%);
      @include desktop-and-tablet {
        transform: translateX(0%);
      }
    }

    .active-exit-active & {
      display: block;
      @include transition(transform, $transition-time);
      transform: translateY(-103%);

      @include desktop-and-tablet {
        transform: translateX(103%);
        @include transition(
          transform,
          $transition-time,
          cubic-bezier(0.63, -0.32, 0.61, 1)
        );
      }
    }

    .active-exit-done & {
      display: none;
      transform: translateY(-103%);

      @include desktop-and-tablet {
        transform: translateX(103%);
      }
    }
  }

  &__mobile-logo {
    display: inline-flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    top: 0;
    left: $horizontal-margin-phone;
    transform: translateY(-100px);
    transition: transform $transition-time;

    @include desktop-and-tablet {
      display: none;
    }

    .logo-container {
      display: flex !important;
    }

    &.active {
      transform: translateY(0);
    }

    .menu-open & {
      transform: translateY(0);
      transition: transform $transition-time / 6;

      .logo-container {
        display: block;
      }
    }
  }

  .logo-container {
    @include logo-container;
    display: none;

    @include desktop-and-tablet {
      display: flex;
      justify-content: flex-start;
      align-self: center;
      margin: 0;
    }
    svg {
      height: 14px;
      width: auto;

      @include desktop-and-tablet {
        height: 15px;
      }

      @include desktop {
        height: 17px;
      }
    }
  }

  &__accounts {
    display: block;
    margin: $vert-margin 0;
    justify-content: center;

    @include phone {
      max-width: 212px;
    }

    @include desktop-and-tablet {
      margin: $vert-margin $sidebar-width / 2;
      padding: 0 $horizontal-margin * 2;
      display: flex;
    }

    .main-menu__column {
      flex-basis: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      padding: $vert-margin 0;// $horizontal-margin-phone / 2;
      position: relative;

      &:first-child {
        border-bottom: 1px solid;
        width: 100%;

        @include desktop-and-tablet {
          border-bottom: 0;
          padding-right: $horizontal-margin;
          padding-left: 0;
        }
      }

      &:last-child {
        @include desktop-and-tablet {
          border-left: 1px solid $black;
          padding-right: 0;
          padding-left: $horizontal-margin;
        }
      }
    }

    h2 {
      @include domaine(38px);
      margin: 0 0 0.25em;
    }
  }

  &__links {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: $vert-margin * 4 0 $vert-margin;

    @include desktop-and-tablet {
      margin: 0;
    }

    h2 {
      margin: 0;
      line-height: 1;
    }
  }

  &__detail {
    text-align: center;
    @include tiempos(17.6px);
    line-height: percentage(24.8 / 17.6);
    max-width: 185px;

    p {
      @include phone {
        margin: 2em 0;
      }
    }

    a {
      display: block;
      margin: $vert-margin 0;

      @include desktop-and-tablet {
        margin: $vert-margin * 2 0;
      }
    }
  }

  &__subnav {
    @include tiempos(13px);
    ul {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      margin: $vert-margin * 2 0;

      @include desktop-and-tablet {
        flex-direction: row;
        margin: auto 0;
      }

      li {
        padding: 0.5em 10px;
        text-align: center;

        @include desktop-and-tablet {
          padding: 0 10px;
        }
      }
    }
  }

  &__endnote {
    @include tiempos(13px);
    text-align: center;
    margin-bottom: $vert-margin * 2;

    @include phone {
      padding-bottom: $vert-margin * 5;
    }
  }

  a {
    &:hover {
      @include desktop-and-tablet {
        color: $cream;
      }
    }

    &.current-page {
      color: $cream;
    }
  }
}

html.menu-open-no-scroll {
  overflow: hidden;
}

body.menu-open {
  // height: 100vh;
  // overflow: hidden;
  // #content {
  //   max-height: 100vh;
  //   overflow: hidden;
  // }
}

.mini-cart {
  &__container {
    .cart--page & {
      display: none;
      pointer-events: none;
    }

    .cart-wines--page & {
      display: none;
      pointer-events: none;
    }

    .shoptools-page & {
      display: none;
      pointer-events: none;
    }

    .checkout-in-process & {
      display: none;
      pointer-events: none;
    }
    .payment__page & {
      display: none;
      pointer-events: none;
    }

    position: fixed;
    top: 0;
    left: $horizontal-margin-phone / 2;
    width: 300px;
    height: 100vh;
    background: $white;
    z-index: 1;
    display: none;
    padding: $vert-margin $horizontal-margin-phone * 2 $vert-margin
      $horizontal-margin-phone;

    @include desktop-and-tablet {
      left: unset;
      right: $sidebar-width-half;
      padding: $vert-margin $horizontal-margin * 2 $vert-margin
        $horizontal-margin;
    }

    @include desktop {
      right: $sidebar-width;
      width: 400px;
    }

    &.active-enter {
      display: block;
      transform: translateY(-103%);
      @include desktop-and-tablet {
        transform: translateX(103%);
      }
    }

    &.active-enter-active {
      display: block;
      @include transition(transform, $transition-time);
      transform: translateY(0%);

      @include desktop-and-tablet {
        transform: translateX(calc(0% + #{$horizontal-margin}));
        @include transition(
          transform,
          $transition-time,
          cubic-bezier(0.4, 0.18, 0.16, 1.27)
        );
      }
    }

    &.active-enter-done {
      display: block;
      transform: translateY(0%);
      pointer-events: all;

      @include desktop-and-tablet {
        transform: translateX(calc(0% + #{$horizontal-margin}));
      }
    }

    &.active-exit {
      display: block;
      transform: translateY(0%);
      @include desktop-and-tablet {
        transform: translateX(calc(0% + #{$horizontal-margin}));
      }
    }

    &.active-exit-active {
      display: block;
      @include transition(transform, $transition-time);
      transform: translateY(-103%);

      @include desktop-and-tablet {
        transform: translateX(103%);
        @include transition(
          transform,
          $transition-time,
          cubic-bezier(0.63, -0.32, 0.61, 1)
        );
      }
    }

    &.active-exit-done {
      display: none;
      transform: translateY(-103%);

      @include desktop-and-tablet {
        transform: translateX(103%);
      }
    }
  }

  &__title {
    margin: $vert-margin 0 1em;
    @include tiempos(20px);
  }

  &__quantity {
    position: absolute;
    top: 0;
    right: 60px;
    color: $white;
    height: $sidebar-mobile-height;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include desktop-and-tablet {
      height: auto;
      position: initial;
      top: unset;
      left: unset;
      right: unset;
      display: block;
    }

    > .active {
      display: flex;
      align-items: center;
      @include desktop-and-tablet {
        display: block;
      }
    }
    .cart--page & {
      display: none;
      pointer-events: none;
    }
    .cart-wines--page & {
      display: none;
      pointer-events: none;
    }
    .shipping-details--page & {
      display: none;
      pointer-events: none;
    }

    .shoptools-page & {
      display: none;
      pointer-events: none;
    }

    .checkout-in-process & {
      display: none;
      pointer-events: none;
    }

    .payment__page & {
      display: none;
      pointer-events: none;
    }

    &:hover {
      color: $black;

      .mini-cart__quantity--inner {
        border-color: $black;
      }

      .cart__link {
        color: $black;
      }
    }

    > *:not(.active) {
      display: none;
      pointer-events: none;
    }

    a.current-page {
      color: $black;
    }

    &--inner {
      margin: 0 $horizontal-margin-phone / 2 0 0;
      border: solid 2px $white;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      text-align: center;
      display: inline-flex;
      flex-direction: column;
      justify-content: center;
      @include soehne($body-font-max);

      @include desktop-and-tablet {
        display: flex;
        margin: $vert-margin auto 0;
        width: 40px;
        height: 40px;
      }

      span {
        line-height: 1;
      }
    }

    &--text {
      @include soehne($body-font-max);
      display: none;

      @include desktop-and-tablet {
        display: block;
        margin: 0.5em auto 0;
      }
    }

    .cart__link {
      color: $white;
    }
  }

  .form-row {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: $vert-margin;
    border-bottom: 1px solid $black;
    margin-bottom: $vert-margin;
    @include soehne($body-font-min);
  }

  .description.cell {
    flex-basis: 100%;
    padding-bottom: 0.5em;
  }

  .quantity.cell {
    display: inline-block;
    input[type='number'] {
      width: calc(4ch + 1em);
      margin-left: 1em;
    }
  }

  .line-total.cell {
    display: inline-block;
    margin-left: 1em;
  }

  .remove.cell {
    flex-grow: 1;
    text-align: right;
    @include soehne($body-font-max);
    padding: 0;

    input {
      padding: 0.75rem;
      border: none;
      outline: none;
    }
  }

  .subtotal {
    display: flex;
    justify-content: flex-start;
    @include soehne($body-font-min);
    span {
      flex-basis: 30%;
      margin-right: auto;
    }
  }

  &__checkoutlink {
    display: none;
    &.active {
      display: inline-block;
    }

    &:hover {
      opacity: $hover-opacity;
    }
  }

  &__checkoutlink--container {
    display: block;
    text-align: center;
    margin-top: 4em;
    @include soehne($body-font-min);
    letter-spacing: 1px;
    text-transform: uppercase;
  }
}

.cart-open {
  #content {
    &:after {
      content: ('');
      opacity: 0.4;
    }
  }
}
