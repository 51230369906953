@import 'c/main/src/constants';
@import 'c/main/src/type';

.contact-page.initialised {
  opacity: 1;
  @include short-desktop() {
    grid-template-rows: min-content $h1-container-height 0;
  }

  .winelogo__container-link,
  .logo-container,
  .content  {
    @include phone {
      margin: 0 $horizontal-margin-phone / 2;
    }
  }

  .contact {
    &__content {
      grid-column: 1 / -1;

      @media screen and (min-width: $desktop-min) and (max-height: $vertical-breakpoint-m) {
        grid-row: 1 / -1;
      }

      &-title {
        margin-right: $horizontal-margin-phone / 2;
        margin-left: $horizontal-margin-phone / 2;
        justify-content: left;

        @include desktop-and-tablet {
          margin-right: $horizontal-margin;
          margin-left: $horizontal-margin;
          position: relative;
        }

        h1 {
          text-align: center;
          margin-bottom: $vert-margin * 4;

          @include desktop-and-tablet {
            margin-top: 1em;
            margin-bottom: 2rem;
            text-align: left;
            order: unset;
          }

          @include desktop {
            position: initial;
          }
        }
      }

      &-detail {
        margin-right: $horizontal-margin-phone / 2;
        margin-left: $horizontal-margin-phone / 2;
        display: block;

        @include desktop-and-tablet {
          margin-right: $horizontal-margin;
          margin-left: $horizontal-margin;
          display: flex;
        }

        @include desktop {
          padding-left: 1em;
        }

        &--column {
          margin-right: $horizontal-margin-phone / 2;
          margin-top: 0;
          width: 100%;
          text-align: center;

          @include phone {
            p {
              @include tiempos(14.9px);
              margin: 4em 0;
            }
          }

          @include desktop-and-tablet {
            margin-right: $horizontal-margin;
            width: percentage(1/3);
            text-align: left;
          }

          @include short-desktop {
            margin-top: 0;
          }
        }
      }
    }

    &__images {
      margin: 0 0 $nextPageMobileHeight 0;
      width: 100%;
      display: none;

      @include desktop-and-tablet {
        @include bottom-full-container;
        margin-right: 0;
        margin-bottom: $vert-margin;
        padding-bottom: 0;
        display: revert;
      }

      @include desktop {
        @include bottom-full-container;
        margin-right: 0;
        margin-bottom: 0;
      }

      @include shortest-desktop {
        display: none;
      }

      .slide {
        @include hero-grid;
        flex-direction: column;

        @include phone {
          padding-left: $horizontal-margin-phone / 2;
          margin-right: 0;
          width: 100vw;
          height: auto;
        }
        &:last-child {
          margin-right: 0;
        }

        > * {
          @include desktop-and-tablet {
            margin-right: $horizontal-margin / 2;
          }
        }

        .image {
          height: 100vw;
          margin-bottom: $vert-margin / 2;
          @include phone {
            width: 100%;
          }

          @include desktop-and-tablet {
            margin-bottom: 0;
            height: 100%;
          }
        }

        &__index {
          @include soehne(10px);
          padding-left: $horizontal-margin-phone / 2;
          margin-bottom: 2em;

          @include phone {
            width: 100%;
          }

          @include desktop-and-tablet {
            padding-left: $horizontal-margin;
          }

          @include short-desktop {
            display: none;
          }
        }
      }
    }
  }

  .logo-container {
    margin-left: $horizontal-margin-phone / 2;
    margin-bottom: $vert-margin / 2;

    @supports (display: grid) {
      grid-column: 1 / 2;
      grid-row: 1 / 2;
    }

    @include phone {
      @include logo-container;
      margin-bottom: 0;
      padding-top: 2.5vh;
      height: auto;
    }

    @include desktop-and-tablet {
      @include logo-container;
      align-items: center !important;
      margin-left: $horizontal-margin / 2;
    }

    @include desktop {
      @include logo-container;
      align-self: flex-end;
      margin-left: 0;
      margin-bottom: 0;
    }
  }
}
