@import 'c/main/src/constants';
@import 'c/main/src/type';

#content > section.product-detail-page.initialised {
    opacity: 1;
    @include mobile-grid;
  
    // // product cart should be in here where logo usually is
    // @supports (display: grid) {
    //   grid-template-rows: max-content max-content 1fr 1fr;
    // }
  
    @include desktop-and-tablet {
      @include tablet-grid($scroll: true);
  
      @supports (display: grid) {
        grid-template-rows: max-content max-content 1fr;
      }
    }
  
    @include tablet {
      @supports (display: grid) {
        grid-template-columns: 2fr 3fr 1fr;
      }
    }
    @include desktop {
      @include desktop-grid($scroll: true);
  
      @supports (display: grid) {
        grid-template-rows: max-content max-content 1fr;
      }
  
      @include wide-desktop {
        @supports (display: grid) {
          grid-template-columns: minmax(420px, 36.66vw) minmax(500px, 3.5fr);
        }
      }
    }
  
    > * {
      @include phone {
        margin: 0 $horizontal-margin-phone / 2;
      }
    }
  
    > h1 {
      display: block;
      grid-row: 2 / 3;
      margin: 1em $horizontal-margin-phone / 2;
  
      @include phone {
        @include domaine(16vw);
  
        @include phone-landscape {
          @include domaine(72px * $min-font-ratio);
        }
      }
  
      @include desktop-and-tablet {
        display: none;
      }
    }
  
    .logo-container {
      text-align: right;
      margin: $vert-margin $horizontal-margin-phone;
      @include desktop-and-tablet {
        margin: 0 $horizontal-margin $vert-margin;
      }
    }
  
    .restricted-filter {
      height: 100vh;
      top: 0;
      padding-top: 200px;
    }
  
    strong {
      font-weight: 400;
      text-transform: uppercase;
    }
  
    .shop {
      grid-row: 4 / 5;
      grid-column: 1 / 2;
      margin-top: $vert-margin / 2;
      margin-bottom: $vert-margin;
      @include soehne(12px);
  
      @include desktop-and-tablet {
        grid-column: 2 / 4;
        grid-row: 1 / 2;
        margin-bottom: 0;
        height: 5em;
  
        @include wide-desktop {
          height: 5vh;
        }
      }
  
      @include desktop {
        grid-column: 2 / 3;
        grid-row: 1 / 2;
        height: 5vh;
      }
  
      form {
        display: flex;
        flex-wrap: wrap;
  
        @include desktop-and-tablet {
          display: block;
          position: fixed;
          z-index: 1;
          width: 100%;
          top: 0;
          background: $cream;
          padding: $vert-margin / 2 0;
        }
  
        .errorlist {
          @include phone {
            flex-basis: 100%;
          }
        }
  
        &.valid {
          .errorlist {
            display: none;
          }
        }
  
        .gotocart {
          @include phone {
            flex-basis: 100%;
            order: 7;
          }
        }
      }
      form span {
        @include tablet {
          margin-right: 2ch;
        }
        @include desktop {
          margin-right: $horizontal-margin;
        }
      }
  
      form select {
        text-transform: uppercase;
        margin-right: $horizontal-margin-phone / 2;
        width: 40%;
  
        @include desktop-and-tablet {
          width: unset;
          margin-right: $horizontal-margin;
  
        }
  
        @include tablet {
          margin-right: $horizontal-margin / 2;
        }
  
        @include desktop {
          min-width: 120px;
          margin-right: $horizontal-margin;
        }
  
      }
  
      form span.shop__price {
        @include phone {
          order: 4;
          width: auto;
          flex-basis: 25%;
          padding-top: 1em;
          position: relative;
        }
        @include desktop-and-tablet {
          display: inline-flex;
          flex-wrap: wrap;
          width: 120px;
          margin-right: $horizontal-margin;
        }
  
        @include tablet {
          width: 100px;
          margin-right: $horizontal-margin / 2;
        }
  
        span {
          flex-basis: 50%;
          // padding-right: 8em;
          margin-right: 0;
          line-height: 1.5;
          padding-top: 1em;
          @include desktop-and-tablet {
            padding-top: 0;
          }
  
          @include tablet {
            flex-basis: 40%;
          }
        }
  
        .strikethrough {
          text-decoration: line-through;
        }
  
        .strikethrough + span {
         position: absolute;
         bottom: -1em;
         left: 0;
          @include desktop-and-tablet {
            display: block;
            position: initial;
            bottom: unset;
            left: unset;
            margin-left: 50%;
            // margin-right: 2ch;
          }
  
          @include tablet {
            margin-left: 40%;
          }
        }
      }
  
      form span.gotocart {
        @include soehne($body-font-min);
        letter-spacing: 0.6px;
        text-transform: uppercase;
  
        @include tablet {
          margin-left: 0;
        }
  
        @include desktop {
          margin-left: $horizontal-margin;
        }
      }
  
      form label {
        margin-left: 0;
        @include phone {
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
        @include desktop-and-tablet {
          margin-left: $horizontal-margin;
        }
  
        @include tablet {
          margin-left: 0;
        }
      }
  
      form input {
        margin-right: $horizontal-margin-phone / 2;
  
        @include desktop {
          margin-right: $horizontal-margin * 2;
        }
      }
  
      input[type='submit'] {
        @include soehne($body-font-min);
        letter-spacing: 0.6px;
        text-transform: uppercase;
        padding: 0;
  
        @include phone {
          order: 5;
          padding: $vert-margin 0;
          margin-right: 75%;
          width: 25%;
        }
  
        @include desktop-and-tablet {
          &:hover {
            opacity: $hover-opacity;
          }
        }
      }
    }
  
    .product {
      &__subnav {
        grid-row: 1 / 2;
        grid-column: 1 / 2;
  
        @include desktop-and-tablet {
          grid-row: 2 / 3;
          grid-column: 2 / 4;
  
          @include wide-desktop {
            z-index: 2;
            width: 50%;
            margin-left: 50%;
          }
        }
  
        @include desktop {
          grid-row: 2 / 3;
          grid-column: 2 / 4;
          z-index: 2;
          width: 50%;
          margin-left: 50%;
        }
      }
      &__toindex {
        display: none;
  
        @include desktop-and-tablet {
          grid-row: 2 / 3;
          grid-column: 2 / 4;
          display: flex;
          padding-top: 4em;
          width: 60%;
        }
  
        @include desktop {
          grid-row: 2 / 3;
          grid-column: 2 / 4;
        }

        a {
          @include tablet {
            flex-basis: calc(100px + #{$horizontal-margin / 2});
          }
          @include desktop {
            flex-basis: calc(120px + #{$horizontal-margin});
          }
        }
      }
      &__image {
        grid-row: 3 / 4;
        grid-column: 1 / 2;
        margin-bottom: $vert-margin / 2;
        background: $darkblack;
        padding: 2em 0;
        height: calc(150vw + 4em);
  
        @include desktop-and-tablet {
          margin-right: $horizontal-margin;
          margin-bottom: 0;
          @include full-left-container;
          height: 100%;
          padding: 0;
        }
  
        @include desktop {
          @include wide-desktop {
            margin-right: 5 * $horizontal-margin;
          }
        }

        .mobile-only {
          @include desktop-and-tablet {
            display: none;
          }
        }
        .desktop-only {
          @include phone {
            display: none;
          }
        }
  
        .image {
          width: 100%;
          height: calc(120vw + 4em);
  
          @include desktop-and-tablet {
            height: 100vh;
            position: sticky;
            bottom: 0;
            left: 0;
          }
  
          img {
            object-fit: contain;
            object-position: center center;
          }
        }
      }
  
      &__content {
        grid-row: 5 / 6;
        grid-column: 1 / 2;
        margin-right: $horizontal-margin-phone;
        margin-bottom: 260px;
  
        @include desktop-and-tablet {
          margin-right: $horizontal-margin;
          @include full-large-container($scroll: true);
          grid-row: 3 / 4 !important;
        }
  
        @include desktop {
          @include full-right-container($scroll: true);
          grid-row: 3 / 4 !important;
        }
  
        h1 {
          max-width: 600px;
          display: none;
          @include desktop-and-tablet {
            display: block;
            white-space: pre-line;
            width: 40vw;
            min-width: 500px;
            max-width: 600px;
          }
        }
  
        em {
          @include tiempos($body-font-min);
          width: 50%;
          display: block;
          margin-left: 50%;
          font-style: normal;
  
          @include desktop {
            @include tiempos(
              $body-font-min * $min-font-ratio,
              $body-font-min * $min-font-ratio,
              $body-font-max * $min-font-ratio
            );
          }
        }
      }
  
      &__container {
        display: flex;
        flex-wrap: wrap;
      }
  
      &__thumb {
        order: 2;
        flex-basis: 60%;
        margin-right: 40%;
        margin-bottom: $vert-margin;
  
        @include desktop-and-tablet {
          order: unset;
          flex-basis: 35%;
          margin-right: auto;
          margin-top: 7em;
          @include wide-desktop {
            margin-right: 150px;
            flex-basis: 25%;
          }
        }
  
        @include desktop {
          margin-right: 150px;
          flex-basis: 25%;
        }
  
        .image {
          @include phone {
            margin: 2em 0 4em;
          }
  
          img {
            width: 100%;
            height: auto;
          }
        }
      }
  
      &__thumb-caption {
        @include tiempos(10px);
        line-height: 1.4;
        margin: 0.5em 0;
  
        @include desktop-and-tablet {
          @include tiempos(10px, 10px, 12px);
        }
      }
  
      &__detail {
        flex-basis: 100%;
        order: 1;
        margin-bottom: $vert-margin;
        margin-left: 25%;
        line-height: 1.5;
  
        @include desktop-and-tablet {
          order: unset;
          flex-basis: 50%;
          margin-right: auto;
          margin-left: auto;
          max-width: 320px;
          line-height: 1.45;
  
          @include wide-desktop {
            flex-basis: 380px;
            max-width: 380px;
            margin-right: auto;
            margin-left: 0;
          }
        }
  
        @include desktop {
          flex-basis: 320px;
          margin-right: auto;
          margin-left: 0;
  
          @include wide-desktop {
            flex-basis: 380px;
            max-width: 380px;
            margin-right: auto;
            margin-left: 0;
          }
        }
      }
  
      &__notes {
        @include tiempos(10px);
        line-height: 1.45;
        order: 3;
        flex-basis: 60%;
        margin-right: 40%;
  
        @include desktop-and-tablet {
          @include tiempos(10px, 10px, 12px);
          order: unset;
          flex-basis: 35%;
          margin-right: auto;
          margin-left: 0;
          margin-top: 10em;
          margin-bottom: 5em;
  
          @include wide-desktop {
            margin-right: 150px;
            flex-basis: 25%;
          }
        }
  
        @include desktop {
          margin-right: 150px;
          flex-basis: 25%;
        }
      }
  
      &__awards {
        @include tiempos(10px);
        line-height: 1.45;
        flex-basis: 60%;
        margin-right: 40%;
        order: 4;
  
        @include desktop-and-tablet {
          @include tiempos(10px, 10px, 12px);
          order: unset;
          flex-basis: 50%;
          margin-right: auto;
          margin-left: auto;
          margin-top: 3em;
          max-width: 320px;
  
          @include wide-desktop {
            flex-basis: 380px;
            margin-right: auto;
            margin-left: 0;
          }
        }
  
        @include desktop {
          flex-basis: 320px;
          margin-right: auto;
          margin-left: 0;
  
          @include wide-desktop {
            flex-basis: 380px;
          }
        }
  
        a {
          text-decoration: underline;
  
          &:hover {
            text-decoration: none;
          }
        }
      }
  
      &__backlink {
        order: 5;
        margin: 2em 0;
        @include tiempos(10px);
  
        @include desktop-and-tablet {
          display: none;
        }
      }
    }
    .notes {
      &__title {
        margin-top: 3em;
      }
      &__links {
        margin-top: 1em;
        a {
          margin-right: 1ch;
        }
      }
    }


  .club-price {
    display: flex;
    margin-bottom: 1em;

    &__price {
      margin-right: $horizontal-margin;
    }

    a {
      margin-right: 2ch;
    }
  }
  }
  