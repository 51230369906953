@import 'c/main/src/constants';
@import 'c/main/src/type';

.distributors-page.initialised {
  display: block !important;
  padding: $vert-margin $horizontal-margin-phone / 2;
  opacity: 1;

  @include desktop-and-tablet {
    padding: $vert-margin $horizontal-margin;
    height: 100vh;
  }

  .logo-container {
    text-align: right;
  }

  h1 {
  }

  .region {
    display: inline-flex;
    justify-content: left;
    width: 100%;
    @include tiempos(14.9px);
    margin-bottom: 1em;

    @include desktop-and-tablet {
      width: 50%;
    }
    @include tablet {
      width: 40%;
    }
    @include desktop {
      justify-content: space-evenly;
    }

    &__select {
      margin-right: $horizontal-margin-phone;

      @include desktop {
        margin-left: 15%;
        margin-right: 0;
      }
    }

    &__regions {
      cursor: pointer;
      margin-right: $horizontal-margin-phone / 2;

      @include desktop-and-tablet {
        margin-right: $horizontal-margin;
      }

      @include desktop {
        margin-right: 15%;
      }
    }

    &__choice {
      &.active {
        opacity: $hover-opacity;
      }
    }
  }
  .distributor {
    display: none;
    margin-bottom: $vert-margin / 2;
    @include tiempos(14.9px);

    @include desktop-and-tablet {
      margin-bottom: $vert-margin;
    }

    &__container {
      display: inline-flex;
      flex-direction: column;
      width: 100%;

      @include desktop-and-tablet {
        width: 25%;
      }
    }

    &.active {
      display: block;
    }
  }
}
