@import "c/main/src/constants";
@import "c/main/src/type";



.smalltown-homepage.initialised {
  background-color: $realblack;

  @include soehne-mono(12px);
  text-transform: uppercase;
  .content {
    height: 100vh;
    height: 100dvh;
    overflow: hidden;
    position: relative;
    &:first-child {
      z-index: 2;
      pointer-events: none;
    }
    @include phone {
      &:not(:first-child) {
        height: auto;
        min-height: 100vh;
      }
    }
  }

  .content--inner {
    color: $white;

    &.content-title {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      opacity: 0;
      cursor: none !important;
      * {
        cursor: none !important;
      }
      &.active {
        opacity: 1;
        transform: translateY(0);
        transition: transform 1s ease-in-out, opacity 1s ease-in-out;
      }
      .home-link {
        text-align: center;
        @include tiempos(20px);
        letter-spacing: 0.2em;
        margin: 1em auto 0;
        line-height: 1.25;
        pointer-events: all;
        @include phone {
          position: fixed;
          left: 0;
          right: 0;
          top: 78svh;
        }
        @include desktop-and-tablet {
          transition: transform 500ms ease-in-out;
        }
      }    
      .arrow-down {
        pointer-events: all;
        cursor: none !important;
        // transition: opacity 100ms;
        // transition-delay: 500ms;
        // cursor: pointer;
      }
    }
    &.content-content {
      text-align: center;
      h3 {
        @include mfred(65px);
        line-height: 0.8;
        margin: 0;
      }
      .text-top,
      .text-bottom {
        width: calc(100% - 2rem);
        max-width: 45rem;
        margin: 0 auto;
      }
      .hours {
        width: calc(100% - 2rem);
        max-width: 65rem;
        margin: 5vh auto;
        @include mfred(65px);
        line-height: 0.8;
        p {
          margin: 0;
        }
      }
      nav {
        width: calc(100% - 2rem);
        max-width: 95rem;
        margin: 5vh auto;
        a {
          @include default-anchor-style;
        }
      }
    }
    @include phone {
      &.content-content {
        margin-bottom: 5vh;
        .hours {
          p {
            margin-bottom: 1rem;
          }
        }
        nav {
          div {
            margin-bottom: 1rem;
          }
        }
      }
    }
    @include desktop-and-tablet {
      transform: translateY(0.5rem);
      padding: $vert-margin * 2.5 $vert-margin * 2.5 $vert-margin * 5;
      &.active {
        transform: translateY(0);
      }
      &.content-title {
        position: fixed;
      }
      &.content-content {
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        flex-direction: column;
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 0;
        &.active {
          transition: opacity 500ms;
        }
        .hours {
          display: flex;
          align-items: flex-start;
          justify-content: space-around;
        }
        nav {
          display: flex;
          align-items: flex-start;
          justify-content: space-around;
        }
      }
    }
  }

  .background-image {
    width: 100%;
    height: 100%;
    opacity: 0.15;
    &.active {
      opacity: 1;
      transition: opacity 1s;
    }

    .image {
      height: 100%;
      width: 100%;
      object-fit: contain;
      img {
        object-fit: contain;
      }
    }
    @include phone {
      padding: $vert-margin * 5 $vert-margin * 1.5;
    }
    @include desktop-and-tablet {
      padding: $vert-margin * 5 $vert-margin * 5 $vert-margin * 7.5;
      position: fixed;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
    }
  }

  .title {
    text-transform: capitalize;
    line-height: 0.75;
    margin: 0;

    @include phone {
      overflow: hidden;
      @include mfred(220px, 200px);
      margin-top: $vert-margin * 1.5;
      .title-wrapper {
        white-space: nowrap;
        display: inline-block;
        .group-01 {
          display: inline-block;
          padding: 0 0.2em;
        }
        .group-02 {
          display: inline-block;
          padding: 0 0.2em;
        }
      }
    }
    @include desktop-and-tablet {
      @include mfred(220px, 220px);
      width: 100%;
      height: 100%;
      .title-wrapper {
        width: 100%;
        height: 100%;
        .group-01 {
          display: flex;
          flex-wrap: wrap;
          width: 100%;
          min-height: 100%;
          justify-content: space-between;
          align-items: flex-start;
          span {
            transition: transform 500ms ease-in-out;
          }
          span:nth-child(3) {
            width: 100%;
            text-align: center;
            align-self: flex-end;
          }
        }
        .group-02 {
          display: none;
        }
      }
    }
    @include tablet {
      @include mfred(220px, 150px);
    }
  }

  .arrow-down {
    @include phone {
      position: absolute;
      left: 50%;
      top: 92svh;
      transform: translate(-50%, -50%);
    }
    @include desktop-and-tablet {
      position: absolute;
      left: 80%;
      top: 50svh;
      transform: translate(-50%, -50%);
    }
  }

  @include desktop-and-tablet {
    &.shift {
      .background-image {
        opacity: 0 !important;
      }
      .content-title {
        .group-01 {
          span:nth-child(1),
          span:nth-child(2) {
            transform: translate3d(0, -100%, 0);
          }
          span:nth-child(3){
            transform: translate3d(0, 100%, 0);
          }
        }
        .home-link {
          transform: translate3d(0, 1000%, 0);
        }
        .arrow-down {
          opacity: 0 !important;
          pointer-events: none;
          // transition-delay: 0ms;
        }
      }
      .content-content {
        opacity: 1;
        transition-delay: 500ms !important;
      }
    }
  }
}
