@import 'c/main/src/constants';
@import 'c/main/src/type';

.map-canvas {
  &.default {
    background: $black;
  }

  &.initialised,
  html.no-js & {
    background: none;

    .mapboxgl-canvas-container {
      visibility: inherit;
    }
  }

  .mapboxgl-canvas-container {
    visibility: hidden;
  }

  .leaflet-control {
    &.leaflet-disabled,
    .leaflet-disabled {
      display: none;
    }
  }

  .custom-marker {
    .marker-dot {
      // @include sohne(11.5px);
      background: $black;
      border: 0;
      border-radius: 50%;
      color: $black;
      line-height: 25px;
      text-align: center;
    }
  }

  .itinerary-day-marker,
  .listing-background-marker,
  .listing-detail-marker {
    .marker-dot {
      position: absolute;
      top: 50%;
      left: 50%;
    }
  }

  .itinerary-day-marker,
  .listing-background-marker {
    visibility: hidden;

    &.selected,
    &.current {
      visibility: inherit;
    }

    .marker-dot {
      width: 25px;
      height: 25px;
      margin-top: -12.5px;
      margin-left: -12.5px;
    }
  }

  .listing-detail-marker {
    .marker-dot {
      width: 20px;
      height: 20px;
      margin-top: -10px;
      margin-left: -10px;
    }
  }

  .itinerary-day-marker {
    &.selected {
      z-index: 2;
    }
  }

  .listing-background-marker {
    &.selected {
      z-index: 3;
    }

    &.current {
      z-index: 4;

      .marker-inner {
        position: absolute;
        height: 50000px;
        width: 50000px;
        border: 24925px solid rgba(0, 0, 0, 0.5);
        border-radius: 50%;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
      }

      .image {
        position: absolute;
        bottom: 100%;
        left: 100%;
        width: 195px;
        margin-left: -25px;
        margin-bottom: -25px;
        border-radius: 50%;
        border: 8px solid $white;
        visibility: inherit;
      }
    }

    .image {
      visibility: hidden;
    }
  }

  .town-marker {
    visibility: hidden;

    &.selected,
    &.current {
      visibility: inherit;

      &.hidden {
        visibility: hidden;
      }
    }

    .marker-dot {
      @include desktop-and-tablet-landscape {
        width: 42px;
        height: 42px;
      }

      @include tablet-portrait-and-phone {
        width: 30px;
        height: 30px;
      }
    }
  }

  .vineyard-marker {
    width: 18px;
    height: 18px;
    background: $black;
    border-radius: 100%;
    cursor: pointer;

    &.hover-active {
      background: #ff9600;
    }

    &.active {
      background: #ff9600;
    }
  }

  .subregion-marker {
    background: none;
    transition: opacity $transition-time;

    @include domaine(18px);
    letter-spacing: 1.2px;
    text-transform: uppercase;

    @include desktop-and-tablet {
      @include domaine(24px);
    }

    .marker-dot {
      background: none;
      border: none;
    }

    &[data-slug='wairau-valley'] {
      .marker-dot {
        transform: rotate(-15deg);
      }
    }

    &[data-slug='awatere-valley'] {
      .marker-dot {
        transform: rotate(-30deg);
      }
    }

    &[data-slug='kekerengu-coast'] {
      .marker-dot {
        transform: rotate(-55deg);
      }
    }
  }

  &.hide-labels {
    .subregion-marker {
      opacity: 0;
    }
  }

  .mapboxgl-ctrl-logo {
    display: none;
  }
}

.mapbox-container {
  // z-index: 2;
  .mapbox {
    // @include desktop-and-tablet {
    //   margin-left: $horizontal-margin;
    // }
  }
  .map-canvas {
    height: 100%;
  }

  .map__vineyards-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0;
    position: relative;
    @supports (display: grid) {
      grid-template-columns: 2 / 3;
    }

    @include desktop-and-tablet {
      margin: 0 $horizontal-margin;
    }

    h4 {
      @include tiempos-italics(12px);
      line-height: percentage(18 / 12);
      margin: 0;
    }

    &::before {
      @include desktop-and-tablet {
        content: ('');
        margin: auto 0;
        flex-basis: 20%;
      }
    }
  }

  .map__vineyards {
    margin: $vert-margin / 2 0;
    padding: 0 15px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-height: 15vh;
    max-width: 100%;
    overflow-x: scroll;

    @include desktop-and-tablet {
      margin: auto 0;
      max-height: unset;
      max-width: unset;
      padding: 0;
      overflow-x: hidden;
    }

    h4 {
      display: none;

      @include desktop-and-tablet {
        display: block;
      }
    }
  }

  .map__vineyard-container {
    min-width: calc(50vw - 5ch);
    padding: 0.1em;

    @include desktop-and-tablet {
      min-width: unset;
    }
  }

  .map__vineyard {
    @include tiempos(12px);
    line-height: percentage(18 / 12);
    cursor: pointer;
    position: relative;
    display: inline-block;

    + a {
      display: none;
      @include tiempos-italics(12px);
      line-height: percentage(18 / 12);
    }

    &.active {
      &:before {
        content: ('');
        width: 10px;
        height: 10px;
        position: absolute;
        top: 5px;
        left: -15px;
        background: $black;
        opacity: 0.4;
        border-radius: 50%;
      }

      + a {
        display: inline-block;
      }
    }

    &__title {
      display: none;
      @include desktop-and-tablet {
        margin: auto 0;
        flex-basis: 20%;
        display: block;
        z-index: 1;
      }
    }
  }

  .close-icon {
    display: none;
    @include desktop-and-tablet {
      display: block;
      position: absolute;
      top: $vert-margin;
      left: 0;
    }

    &.mobile-only {
      display: block;
      margin: $vert-margin / 2 0 $vert-margin / 2 calc(100% - 24px - #{$horizontal-margin-phone / 2});


      @include desktop-and-tablet {
        display: none;
      }
    }
  }

  .map__nz-map {
    display: none;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: auto;
    @include desktop-and-tablet {
      display: block;
    }

    img {
      width: 100%;
      height: auto;
      max-height: 30vh;
      object-fit: contain;
      object-position: 50% 100%;
    }
  }
}
