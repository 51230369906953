//
// Font-faces
//
@mixin webfont($name, $filename, $weight: 400, $style: normal) {
  // sass-lint:disable-all
  // see https://css-tricks.com/snippets/css/using-font-face/
  @font-face {
    font-family: "#{$name}";
    src: url('./' + $filename + '.woff') format('woff');
    // src: url('./' + $filename + '.eot');
    // src: url('./' + $filename + '.eot?#iefix') format('embedded-opentype'),
    src: url('./' + $filename + '.woff2') format('woff2');
    // src: url('./' + $filename + '.woff') format('woff');
    //      url('./' + $filename + '.ttf') format('truetype'),
    //      url('./' + $filename + '.svg') format('svg');
    font-weight: $weight;
    font-style: $style;
  }
}

@mixin webfont-woff2($name, $filename, $weight: 400, $style: normal) {
  // sass-lint:disable-all
  // see https://css-tricks.com/snippets/css/using-font-face/
  @font-face {
    font-family: "#{$name}";
    src: url('./' + $filename + '.woff2') format('woff2');
    font-weight: $weight;
    font-style: $style;
  }
}

// @include webfont('example', 'ExampleWeb-Light', 300);
// @include webfont('example', 'ExampleWeb-Regular', 400);
// @include webfont('example', 'ExampleWeb-RegularItalic', 400, italic);
@include webfont('Domaine', 'domaine-display-condensed-web-regular', 400);

@include webfont('Tiempos', 'TiemposTextWeb-Regular', 400);
@include webfont('Tiempos-Italics', 'TiemposTextWeb-RegularItalic', 400);

@include webfont('Soehne', 'soehne-web-buch', 400);
@include webfont-woff2('SoehneMono', 'soehne-mono-buch', 400);

@include webfont('MFred', 'MFred2.0-Regular', 400);
@include webfont('MFred', 'MFred2.0-Heavy', 700);