@import 'c/main/src/constants';
@import 'c/main/src/type';

.homepage.initialised {
  @supports (display: grid) {
    @include desktop-and-tablet {
      grid-template-rows: 4fr max-content max-content !important;
    }

    @include tablet-portrait {
      grid-template-rows: minmax(400px, 50vh) max-content calc(
          50vh - #{$logo-container-height}
        ) !important;
    }

    @include desktop {
      grid-template-rows: minmax(460px, 50vh) $logo-container-height calc(
          50vh - #{$logo-container-height}
        ) !important;
    }

    @include short-desktop {
      grid-template-rows: 100vh 0 0 !important;
    }
  }
  .winelogo__container-link,
  .logo-container,
  .content {
    @include phone {
      margin: 0 $horizontal-margin-phone / 2;
    }
  }

  .logo-container {
    @include logo-container;
    grid-row: 1 / 2;

    @include desktop-and-tablet {
      align-items: center;
      grid-row: 1 / 2;
    }

    @include tablet-portrait {
      grid-row: 2 / 3;
    }

    @include desktop {
      grid-row: 2 / 3;
    }

    @include shortest-desktop {
      display: none;
    }
  }

  .hero.scroll-container {
    grid-row: 3 / 4;
    padding-bottom: $vert-margin / 2;
    margin: 0 0 $vert-margin 0;
    position: relative;
    // width: 100vw;

    @include desktop-and-tablet {
      @include top-middle-container;
      margin-right: $horizontal-margin;
      margin-top: 0;
      margin-bottom: 0;
      padding-bottom: 0;
    }

    @include tablet-portrait {
      @include top-right-container;
      margin-right: 0;
      margin-top: 0;
      margin-bottom: 0;
    }

    @include desktop {
      @include top-right-container;
      margin-right: 0;
      margin-top: 0;
      margin-bottom: 0;
    }

    .scroll.carousel {
      @include desktop-and-tablet {
        @include hero-grid;
      }

      .slide {
        @include phone {
          padding-left: $horizontal-margin-phone / 2;

          &:last-child {
            margin-right: 0;
          }
        }
      }

      .image {
        height: 100vw;
        margin-bottom: $vert-margin / 2;

        @include desktop-and-tablet {
          margin-bottom: 0;
          height: 100%;
        }
      }

      .carousel-cell {
        @include phone {
          height: calc(135vw + 3rem);
        }
        .mobile-only {
          display: block;
          width: 50%;

          @include desktop-and-tablet {
            display: none;
          }

          .image {
            height: auto;
          }

          &__text {
            @include tiempos(10px);
          }
        }
      }
    }
  }

  .slider {
    grid-row: 4 / 5;
    padding-bottom: $vert-margin;
    position: relative;
    overflow: hidden;
    display: none;

    @include desktop-and-tablet {
      @include bottom-middle-container;
      padding-bottom: 0;
      margin-right: $horizontal-margin;
      margin-bottom: $vert-margin;
      display: flex;
      flex-direction: column;
      height: auto;
    }

    @include tablet-portrait {
      @include bottom-left-container;
      display: flex;
      flex-direction: column;
      height: calc(100% - #{$vert-margin});
      margin-bottom: $vert-margin;
      margin-left: $horizontal-margin;
    }

    @include desktop {
      @include bottom-left-container;
      display: flex;
      flex-direction: column;
      height: calc(100% - #{$vert-margin});
      margin-bottom: $vert-margin;
      margin-left: $horizontal-margin;
    }

    @include short-desktop {
      display: none;
    }

    .scroll-container {
      @include desktop-and-tablet {
        height: auto;
        flex-grow: 1;
      }
    }

    .slide {
      width: calc(100vw - #{$horizontal-margin-phone});

      @include desktop-and-tablet {
        @include mini-image-grid;
        width: 130vh;
      }

      @include tablet-portrait {
        width: calc(
          (100vw - #{$sidebar-width-half}) * 0.333 + #{$horizontal-margin / 2}
        );
      }

      @include desktop {
        width: calc(#{$left-max-width} - #{$horizontal-margin * 1.5});
        min-width: calc(#{$left-min-width} - #{$horizontal-margin * 1.5});
      }

      &__title {
        @include tiempos(9px);
        padding-top: 0.5em;
      }

      .image {
        margin-right: $horizontal-margin-phone / 2;

        @include desktop-and-tablet {
          margin-right: $horizontal-margin / 2;
        }

        img {
          position: initial;
        }
      }
    }

    .scroll-listener {
      height: 100%;

      @include tablet-portrait {
        margin-top: $vert-margin * 3;
        height: calc(100% - #{$vert-margin * 3});
      }
    }
  }

  .content {
    grid-row: 2 / 3;
    margin-bottom: $vert-margin;

    @include desktop-and-tablet {
      @include full-right-container;
      display: flex;
      flex-direction: column;
      height: 100%;
      padding-bottom: $vert-margin;
      margin: 0 $horizontal-margin 0 0;

      &:before {
        content: ('');
        height: 0px;
      }
    }

    @include tablet-portrait {
      @include bottom-right-container;
      display: flex;
      height: calc(100% - #{$vert-margin});
      padding-bottom: 1.4em;
      margin: 0 $horizontal-margin $vert-margin 0;
      &:before {
        content: none;
      }
    }

    @include desktop {
      @include bottom-right-container;
      display: flex;
      height: calc(100% - #{$vert-margin});
      padding-bottom: 1.4em;
      margin: 0 $horizontal-margin $vert-margin 0;
      &:before {
        content: none;
      }
    }

    &__menu {
      margin: $vert-margin * 2 0 $vert-margin * 3;

      @include desktop-and-tablet {
        margin: auto 0;
      }

      > ul {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0;

        li {
          visibility: visible;

          html.font-loaded & {
            visibility: visible;
          }

          h2 {
            margin: 0;
            line-height: 1;
            @include domaine(50px * $min-font-ratio);

            @include desktop {
              @include domaine(46px, 46px * $min-font-ratio, 60px);
            }
    
            a {
              transition: color $transition-time $transition-time;
              color: rgba(0, 0, 0, 1);
              html.font-loaded & {
                color: rgba(0, 0, 0, 1);
              }

              &:hover {
                opacity: $hover-opacity;
              }
            }
          }
        }
      }
    }

    &__detail {
      @include desktop-and-tablet {
        min-height: 17vh;
      }

      @include tablet-portrait {
        min-height: 0;
        padding-right: 20%;
      }

      @include desktop {
        min-height: 0;

        padding-right: 20%;

        @media screen and (min-width: 2000px) {
          max-width: 900px;
        }
      }
    }
  }
}
