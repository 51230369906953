@import 'c/main/src/constants';
@import 'c/main/src/type';

#content > section.wine-index-page.initialised {
  opacity: 1;
  position: relative;
  > * {
    @include phone {
      margin: 0 $horizontal-margin-phone / 2;
    }
  }

  .logo-container {
    margin-top: $vert-margin;
    margin-right: $horizontal-margin-phone / 2;
    text-align: right;

    @include phone {
      order: 1;
    }

    @include desktop-and-tablet {
      position: absolute;
      top: 0;
      right: 0;
      margin-top: $vert-margin * 3;
      margin-right: $horizontal-margin;
    }
  }

  .wine {
    @include tiempos(12px);

    &__content {
      display: flex;
      flex-direction: column;
      max-width: calc(100vw - #{$horizontal-margin-phone});
      position: relative;

      @include desktop-and-tablet {
        max-width: calc(100vw - #{$horizontal-margin});
        @include full-container;
        width: 100% !important;
        display: flex;
        flex-direction: column;
        height: 100vh;
        padding-left: $horizontal-margin;
      }

      @include desktop {
        width: auto !important;
      }

      > h1 {
        @include transition(opacity, $transition-time);
        @include phone {
          order: 2;
          text-align: center;
        }

        @include short-desktop {
          margin: 20px 0;
        }

        @include shortest-desktop {
          margin: 0 0 5px 0;
        }
      }
    }

    &__filters {
      @include soehne($body-font-min);
      margin-top: $vert-margin;
      @include phone {
        order: 4;
      }
      @include desktop-and-tablet {
        margin-top: $vert-margin / 2;
        display: flex;
        align-items: flex-start;
      }

      .filter__container {
        width: 100%;
        display: flex;

        @include desktop-and-tablet {
          display: inline-block;
          margin-left: 3rem;
          position: relative;
          width: 130px;
          overflow: hidden;
          padding-bottom: 2em;
        }

        &.default {
          padding-bottom: 0;

          @include desktop-and-tablet {
            margin-bottom: 2em;
          }
        }

        label {
          @include phone {
            flex-basis: 25%;
            // padding-top: 0.2em;
            font-size: 16px;
            transform: scale(0.75);
            transform-origin: center left;
          }
        }

        select {
          @include phone {
            flex-basis: 75%;
            // padding: 0.5em;
            padding: 0.2em;
            font-size: 16px;
            transform: scale(0.75);
            transform-origin: center left;
          }
        }
      }

      select {
        margin-left: 1ch;
        border: none;

        @include desktop-and-tablet {
          display: block;
          margin-left: 0;
          padding-left: 0;
          padding-top: 1.6em;
          position: absolute;
          top: 0;
          left: 0;
          background-position: right 1em top 0.5em;
        }

        option {
          @include desktop-and-tablet {
            padding-left: 0;
          }
        }
      }
    }

    &__container {
      flex: 6;
      @include phone {
        width: 100%;
        order: 5;
        margin-top: $vert-margin;
        margin-bottom: $vert-margin * 5;
        height: auto;
      }

      .scroll {
        display: flex;
        @include phone {
          flex-direction: column;
          width: 100% !important;
        }

        &.active-enter {
          opacity: 0;
        }

        &.active-enter-active {
          @include transition(opacity, $transition-time);
          opacity: 1;
        }

        &.active-enter-done {
          opacity: 1;
        }

        &.active-exit {
          opacity: 1;
        }

        &.active-exit-active {
          @include transition(opacity, $transition-time);
          opacity: 0;
        }

        &.active-exit-done {
          opacity: 0;
        }

        &.opacity {
          opacity: 0;
        }
      }

      .wine {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-right: $horizontal-margin-phone / 2;
        margin-bottom: $vert-margin * 3;

        @include desktop-and-tablet {
          width: 35vh;
          margin-right: $horizontal-margin;
          margin-bottom: 0;
        }

        @include short-desktop {
          width: 260px;
        }

        &__image {
          background: $darkblack;
          height: calc(150vw + 4em);

          @include phone {
            order: 1;
            padding: 2em 0;
          }
          @include desktop-and-tablet {
            height: calc(100% - 6rem);
          }

          @include shortest-desktop {
            height: 100%;
          }

          .image {
            width: 100%;
            height: 100%;
          }

          img {
            object-fit: contain;
          }
        }
      }

      .slide__product {
        @include desktop-and-tablet {
          width: 55vh;
          margin-right: $horizontal-margin;
          margin-bottom: 0;
        }
      }
    }
    &__title {
      @include phone {
        order: 2;
        margin-top: 0.5em;
      }
      @include shortest-desktop {
        display: none;
      }
      @include desktop-and-tablet {
        min-height: 4em;
      }
    }

    &__price {
      @include soehne(12px);
      @include phone {
        order: 3;
      }
      @include desktop-and-tablet {
        text-align: center;
      }

      @include shortest-desktop {
        display: none;
      }

      &--strike {
        text-decoration: line-through;
      }
    }

    &__info {
      @include soehne(12px);

      @include desktop-and-tablet {
        margin-bottom: 1em;
      }

      @include shortest-desktop {
        display: none;
      }
    }

    &__notes {
      margin-top: $vert-margin / 2;
      width: 100%;

      @include phone {
        order: 3;
      }

      @include desktop-and-tablet {
        margin-top: 3em;
        width: 50%;
        flex: 1;
      }

      @include short-desktop {
        margin-top: 10px;
      }

      p:first-child {
        margin-top: 0;
      }
      p:last-child {
        margin-bottom: 0;
      }
    }

    &__info-wrapper {
      @include phone {
        order: 4;
      }
      display: flex;
    }

    &__promo-note {
      flex-basis: calc(50% + 4ch);
      margin-left: auto;

      @include desktop-and-tablet {
        flex-basis: calc(50% + 2.5ch);
      }

      @include short-desktop {
        display: none;
      }
    }
  }

  &.restricted-filter-active {
    .wine {
      &__title,
      &__price,
      &__info,
      &__filters {
        visibility: hidden;
      }
    }

    .accounts-box {
      visibility: hidden;
    }
  }
  &.outgoing-page {
    .accounts-box {
      opacity: 0;
    }
  }

  &.login-active {
    .wine {
      &__title,
      &__price,
      &__container,
      &__notes,
      &__info,
      &__filters {
        opacity: 0.1;
        @include transition(opacity, $transition-time);
      }
    }
    h1 {
      opacity: 0.1;
    }
  }
}

#content > section.wine-detail-page.initialised {
  opacity: 1;
  @include mobile-grid;
  position: relative;
  // // Wine cart should be in here where logo usually is
  // @supports (display: grid) {
  //   grid-template-rows: max-content max-content 1fr 1fr;
  // }

  @include desktop-and-tablet {
    @include tablet-grid($scroll: true);

    @supports (display: grid) {
      grid-template-rows: max-content max-content 1fr;
    }
  }

  @include tablet {
    @supports (display: grid) {
      grid-template-columns: 2fr 3fr 1fr;
    }
  }
  @include desktop {
    @include desktop-grid($scroll: true);

    @supports (display: grid) {
      grid-template-rows: max-content max-content 1fr;
    }

    @include wide-desktop {
      @supports (display: grid) {
        grid-template-columns: minmax(420px, 36.66vw) minmax(500px, 3.5fr);
      }
    }
  }

  > * {
    @include phone {
      margin: 0 $horizontal-margin-phone / 2;
    }
  }

  > h1 {
    display: block;
    grid-row: 2 / 3;
    margin: 1em $horizontal-margin-phone / 2;

    @include phone {
      @include domaine(16vw);

      @include phone-landscape {
        @include domaine(72px * $min-font-ratio);
      }
    }

    @include desktop-and-tablet {
      display: none;
    }
  }

  .logo-container {
    text-align: right;
    margin: $vert-margin $horizontal-margin-phone;
    @include desktop-and-tablet {
      margin: 0 $horizontal-margin $vert-margin;
    }
  }

  .restricted-filter {
    height: 100vh;
    top: 0;
    padding-top: 200px;
  }

  strong {
    font-weight: 400;
    text-transform: uppercase;
  }

  .club-price {
    display: flex;
    margin-bottom: 1em;

    &__price {
      margin-right: $horizontal-margin;
    }

    a {
      margin-right: 2ch;
    }
  }

  .shop {
    grid-row: 4 / 5;
    grid-column: 1 / 2;
    margin-top: $vert-margin / 2;
    margin-bottom: $vert-margin;
    @include soehne(12px);

    @include desktop-and-tablet {
      grid-column: 2 / 4;
      grid-row: 1 / 2;
      margin-bottom: 0;
      height: 5em;

      @include wide-desktop {
        height: 5vh;
      }
    }

    @include desktop {
      grid-column: 2 / 3;
      grid-row: 1 / 2;
      height: 5vh;
    }

    form {
      display: flex;
      flex-wrap: wrap;

      @include desktop-and-tablet {
        display: block;
        position: fixed;
        z-index: 1;
        width: 100%;
        top: 0;
        background: $cream;
        padding: $vert-margin / 2 0;
      }

      .errorlist {
        @include phone {
          flex-basis: 100%;
        }
      }

      &.valid {
        .errorlist {
          display: none;
        }
      }

      .gotocart {
        @include phone {
          flex-basis: 100%;
          order: 7;
        }
      }
    }
    form span {
      @include tablet {
        margin-right: 2ch;
      }
      @include desktop {
        margin-right: $horizontal-margin;
      }
    }

    form select {
      text-transform: uppercase;
      margin-right: $horizontal-margin-phone / 2;
      width: 40%;

      @media screen and (max-width: 350px) {
        margin-right: 0;
      }

      @include desktop-and-tablet {
        width: unset;
        margin-right: $horizontal-margin;
      }

      @include tablet {
        margin-right: $horizontal-margin / 2;
      }

      @include desktop {
        min-width: 120px;
        margin-right: $horizontal-margin;
      }
    }

    form span.shop__price {
      @include phone {
        order: 4;
        width: auto;
        flex-basis: 25%;
        padding-top: 1em;
        position: relative;
      }
      @include desktop-and-tablet {
        display: inline-flex;
        flex-wrap: wrap;
        width: 120px;
        margin-right: $horizontal-margin;
      }

      @include tablet {
        width: 100px;
        margin-right: $horizontal-margin / 2;
      }

      span {
        flex-basis: 50%;
        // padding-right: 8em;
        margin-right: 0;
        line-height: 1.5;
        padding-top: 1em;
        @include desktop-and-tablet {
          padding-top: 0;
        }

        @include tablet {
          flex-basis: 40%;
        }
      }

      .strikethrough {
        text-decoration: line-through;
      }

      .strikethrough + span {
        position: absolute;
        bottom: -1em;
        left: 0;
        @include desktop-and-tablet {
          display: block;
          position: initial;
          bottom: unset;
          left: unset;
          margin-left: 50%;
          // margin-right: 2ch;
        }

        @include tablet {
          margin-left: 40%;
        }

        &.shop__clubprice {
          position: absolute;
          left: 0;
          bottom: -2em;
          margin-left: 0;

          @include desktop-and-tablet {
            left: 0;
            bottom: 0;
            margin-left: 0;
          }
        }
      }
    }

    form span.gotocart {
      @include soehne($body-font-min);
      letter-spacing: 0.6px;
      text-transform: uppercase;

      @include tablet {
        margin-left: 0;
      }

      @include desktop {
        margin-left: $horizontal-margin;
      }
    }

    form label {
      margin-left: 0;
      @include phone {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      @include desktop-and-tablet {
        margin-left: $horizontal-margin;
      }

      @include tablet {
        margin-left: 0;
      }
    }

    form input {
      margin-right: $horizontal-margin-phone / 2;

      @include desktop {
        margin-right: $horizontal-margin * 2;
      }
    }

    form input:disabled {
      opacity: 0.4;
    }

    input[type='submit'] {
      @include soehne($body-font-min);
      letter-spacing: 0.6px;
      text-transform: uppercase;
      padding: 0;

      @include phone {
        order: 5;
        padding: $vert-margin 0;
        margin-right: 75%;
        width: 25%;
      }

      @include desktop-and-tablet {
        &:hover {
          opacity: $hover-opacity;
        }
      }
    }
  }

  &.incoming-page {
    .accounts-box {
      opacity: 0;
      transition: opacity $transition-time;
    }
  }

  .accounts-box.logged-in {
    z-index: 10;
    width: auto;
  }

  .wine {
    &__subnav {
      grid-row: 1 / 2;
      grid-column: 1 / 2;

      @include desktop-and-tablet {
        grid-row: 2 / 3;
        grid-column: 2 / 4;

        @include wide-desktop {
          z-index: 2;
          width: 50%;
          margin-left: 50%;
        }
      }

      @include desktop {
        grid-row: 2 / 3;
        grid-column: 2 / 4;
        z-index: 2;
        width: 50%;
        margin-left: 50%;
      }
    }
    &__toindex {
      display: none;

      @include desktop-and-tablet {
        grid-row: 2 / 3;
        grid-column: 2 / 4;
        display: flex;
        padding-top: 4em;
        width: 60%;
      }

      @include desktop {
        grid-row: 2 / 3;
        grid-column: 2 / 4;
      }

      a {
        @include tablet {
          flex-basis: calc(100px + #{$horizontal-margin / 2});
        }
        @include desktop {
          flex-basis: calc(120px + #{$horizontal-margin});
        }
      }
    }

    &__image {
      grid-row: 3 / 4;
      grid-column: 1 / 2;
      margin-bottom: $vert-margin / 2;
      background: $darkblack;
      padding: 2em 0;
      height: calc(150vw + 4em);

      @include desktop-and-tablet {
        margin-right: $horizontal-margin;
        margin-bottom: 0;
        @include full-left-container;
        height: 100%;
        padding: 0;
      }

      @include desktop {
        @include wide-desktop {
          margin-right: 5 * $horizontal-margin;
        }
      }

      .image {
        width: 100%;
        height: calc(120vw + 4em);

        @include desktop-and-tablet {
          height: 100vh;
          position: sticky;
          bottom: 0;
          left: 0;
        }

        img {
          object-fit: contain;
          object-position: center center;
        }
      }
    }

    &__content {
      grid-row: 5 / 6;
      grid-column: 1 / 2;
      margin-right: $horizontal-margin-phone;
      margin-bottom: 260px;

      @include desktop-and-tablet {
        margin-right: $horizontal-margin;
        @include full-large-container($scroll: true);
        grid-row: 3 / 4 !important;
      }

      @include desktop {
        @include full-right-container($scroll: true);
        grid-row: 3 / 4 !important;
      }

      h1 {
        max-width: 600px;
        display: none;
        @include desktop-and-tablet {
          display: block;
          white-space: pre-line;
          width: 40vw;
          min-width: 500px;
          max-width: 600px;
        }
      }

      em {
        @include tiempos($body-font-min);
        width: 50%;
        display: block;
        margin-left: 50%;
        font-style: normal;

        @include desktop {
          @include tiempos(
            $body-font-min * $min-font-ratio,
            $body-font-min * $min-font-ratio,
            $body-font-max * $min-font-ratio
          );
        }
      }
    }

    &__container {
      display: flex;
      flex-wrap: wrap;
    }

    &__thumb {
      order: 2;
      flex-basis: 60%;
      margin-right: 40%;
      margin-bottom: $vert-margin;

      @include desktop-and-tablet {
        order: unset;
        flex-basis: 35%;
        margin-right: auto;
        margin-top: 7em;
        @include wide-desktop {
          margin-right: 150px;
          flex-basis: 25%;
        }
      }

      @include desktop {
        margin-right: 150px;
        flex-basis: 25%;
      }

      .image {
        @include phone {
          margin: 2em 0 4em;
        }

        img {
          width: 100%;
          height: auto;
        }
      }
    }

    &__thumb-caption {
      @include tiempos(10px);
      line-height: 1.4;
      margin: 0.5em 0;

      @include desktop-and-tablet {
        @include tiempos(10px, 10px, 12px);
      }

      a {
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }
    }

    &__detail {
      flex-basis: 100%;
      order: 1;
      margin-bottom: $vert-margin;
      margin-left: 25%;
      line-height: 1.5;

      @include desktop-and-tablet {
        order: unset;
        flex-basis: 50%;
        margin-right: auto;
        margin-left: auto;
        max-width: 320px;
        line-height: 1.45;

        @include wide-desktop {
          flex-basis: 380px;
          max-width: 380px;
          margin-right: auto;
          margin-left: 0;
        }
      }

      @include desktop {
        flex-basis: 320px;
        margin-right: auto;
        margin-left: 0;

        @include wide-desktop {
          flex-basis: 380px;
          max-width: 380px;
          margin-right: auto;
          margin-left: 0;
        }
      }

      a {
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }
    }

    &__notes {
      @include tiempos(10px);
      line-height: 1.45;
      order: 3;
      flex-basis: 60%;
      margin-right: 40%;

      @include desktop-and-tablet {
        @include tiempos(10px, 10px, 12px);
        order: unset;
        flex-basis: 35%;
        margin-right: auto;
        margin-left: 0;
        margin-top: 3em;
        margin-bottom: 5em;

        @include wide-desktop {
          margin-right: 150px;
          flex-basis: 25%;
        }
      }

      @include desktop {
        margin-right: 150px;
        flex-basis: 25%;
      }

      a {
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }
    }

    &__awards {
      @include tiempos(10px);
      line-height: 1.45;
      flex-basis: 60%;
      margin-right: 40%;
      order: 4;

      @include desktop-and-tablet {
        @include tiempos(10px, 10px, 12px);
        order: unset;
        flex-basis: 50%;
        margin-right: auto;
        margin-left: auto;
        margin-top: 3em;
        max-width: 320px;

        @include wide-desktop {
          flex-basis: 380px;
          margin-right: auto;
          margin-left: 0;
        }
      }

      @include desktop {
        flex-basis: 320px;
        margin-right: auto;
        margin-left: 0;

        @include wide-desktop {
          flex-basis: 380px;
        }
      }

      a {
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }
    }

    &__backlink {
      order: 5;
      margin: 2em 0;
      @include tiempos(10px);

      @include desktop-and-tablet {
        display: none;
      }
    }
  }
  .notes {
    &__title {
      margin-top: 3em;
    }
    &__links {
      margin-top: 1em;
      a {
        margin-right: 1ch;
      }
    }
  }
}
